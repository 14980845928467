import {RequestFactoryMap, ResponseFactoryMap} from './MessageFactoryProducer';
import {createProxy} from '@multichannel/sdk/src/api/Proxy';
import constants from '@multichannel/sdk/src/api/constants';
import MultichannelSdk from '@multichannel/sdk';
import Compareable from '@multichannel/sdk/src/Comparable';
import {Observable, Subject} from 'rxjs';
import {catchError, filter, switchAll} from 'rxjs/operators';
import {BLOATED_MESSAGES} from './BloatedMessages';
import {Socket} from './Socket';
import {MessageQueue} from './MessageQueue';
import {ScopedLogger} from '@core/logger';


/**
 * Handler for agentserver web socket messages.
 *
 * The agentserver expects messages in binary format.
 * To save the hassle of creating these message js-objects have been compiled from protobuf messages. These messages can
 * be formatted to a multidimensional array structure mirroring the format in the protobuf-message. This message can the
 * be sent to a protocol translator, which will format the message to the binary format required by the agent server and
 * then passed on.
 *
 * Messages from the agent server pass through the protocol translator, which translates the message to a multidimensional
 * json array: Those arrays can be used to create javascript objects from js-compiled protobuf-messages.
 *
 * This class will also automatically answer ping requests with pong responses.
 *
 * @see {@link https://gitlab.4com.de/agentenserver/protobuf-messages}
 * @see {@link https://wiki.4com.de/display/Entwicklung/Professional+Client}
 */
export class MessageHandler extends Compareable {
  set version(version: number) {
    this._version = version;
  }

  private _logger: ScopedLogger;
  private readonly _proxy: MessageHandler;
  private _version: number;
  private readonly _sdk: MultichannelSdk;
  private lastMessageId: number;
  private _messagesSubject: Subject<FactoryMessageResponse[]>;
  private readonly _messages: Observable<FactoryMessageResponse>;
  /**
   * Instantiates a new MessageHandler.
   */
  constructor(sdk: MultichannelSdk) {
    super();
    this._proxy = createProxy(this);
    this._sdk = sdk;
    this._logger = this._sdk.debug('api.messageHandler');

    this._messagesSubject = new Subject();
    this._messages = this._messagesSubject.pipe(switchAll(), catchError(e => { this._logger.error('Error in Message Pipe', e); throw e; }));

    // This is the message interface version (MIV) of the agent server. It determines which messages we can use and
    // which version of them we want to send or receive. The agent server will bump this version periodically
    // whenever there are "breaking" changes in the protocol.
    //
    // @see https://wiki.4com.de/x/iBKFAw
    //
    // We can either provide a fixed version here or leave it at null. If we do not provide a version by ourselves
    // (by defining this as null) the agent server will use the most recent stable protocol version (not latest
    // per se). There's logic in the InitialisationResponseHandler that will simply set the version to the one the
    // agent server provided us with in the first message (currently disabled).
    //
    this._version = constants.INTERFACE_VERSION;

    this.messagesByType(10042).subscribe(res => this._handlePing(res));

    /* public member */
    this.lastMessageId = 0;


    return this._proxy;
  }

  public get messages(): Observable<any> {
    return this._messages;
  }

  public messagesByType(type): Observable<FactoryMessageResponse> {
    return this._messages.pipe(filter(message => message.type === type));
  }

  /**
   * Handle incoming Ping Request and send Pong Response
   *
   * @see {@link https://gitlab.4com.de/agentenserver/protobuf-messages/blob/master/proto3/10042_AsPingMsg.proto}
   * @see {@link https://wiki.4com.de/display/Entwicklung/Professional+Client#}
   */
  private _handlePing(res: FactoryMessageResponse) {
    const ping = res?.obj;
    this._sdk.messageHandler.send(
      10043,
      this._sdk.sessionKey,
      ping.destroyobjecttimer
    );
  }

  /**
   * Handles a message received from the agentserver.
   * This method constructs js-objects (as defined in protobuf for the message type) from the raw message data. These
   * objects are then used to call the appropriate messages for processing of the message.
   * Some messages also trigger events.
   *
   * Messages in the 190000 range are sent by the ACD and passed through in the agentserver.
   */
  handle = (rawMessage: {data: string}) => {
    let message = null;

    try {
      message = JSON.parse(rawMessage.data || null);
    } catch (e) {
      this._logger.error('Could not parse incoming message', { rawMessage: rawMessage.data, e });
      return;
    }

    // The computed keys of the HANDLERS list result in strings.
    // Convert the (integer) message type to be able to compare properly.
    const messageType = '' + message.Type;
    this.lastMessageId = message.Id;

    // Don't log spam, ie. monitor data.
    if (!BLOATED_MESSAGES.includes(message.Type) || this._sdk.config.flags.enableVerboseLogging) {
      const logObject = {
        'Name': ResponseFactoryMap[messageType]?.factoryName ||'Unhandled Message',
        ...message
      };
      this._logger.traceCall('handle', logObject);
    }

    if (ResponseFactoryMap[messageType]) {

      const factoryGetMessageValue = ResponseFactoryMap[messageType].getMessage.length === 1
        ? ResponseFactoryMap[messageType].getMessage(message?.Data)
        : ResponseFactoryMap[messageType].getMessage(message?.Data, message?.Version);

      const obj = factoryGetMessageValue?.toObject ? factoryGetMessageValue.toObject() : null;

      this._messagesSubject.next([{
        type   : message.Type,
        data   : message.Data,
        message: factoryGetMessageValue,
        obj,
        source : message
      }]);

      return;
    }
  };

  reconnect = () => {
    if (Socket.Socket) {
      Socket.Socket.close();
    }
    this._sdk.setInitialization(false);

    Socket.initSocket(this._sdk, this.handle);
  };

  send(type: number, ...theArgs:any[]): number {
    const factory = RequestFactoryMap[type];
    //@ts-ignore
    const msg = this.wrapMessage(type, factory.getMessage(...theArgs).toArray());

    MessageQueue.Queue.push(msg);
    if (!BLOATED_MESSAGES.includes(type) || this._sdk.config.flags.enableVerboseLogging) {
      this._logger.traceCall('send', {
        'Name': (RequestFactoryMap[type] as any)?.factoryName,
        ...msg
      });
    }

    MessageQueue.sendQueue();
    return msg.Id;
  }

  wrapMessage(type:number, data: {}) {
    this.lastMessageId++;
    return {
      Type   : type,
      Version: this._version,
      Id     : this.lastMessageId,
      Flags  : 1,
      Data   : data,
    };
  }

  public static restrainMessageArgs(args: any[]) {
    return args.filter(arg =>
      arg === null ||
      arg === undefined ||
      (typeof arg === 'number') ||
      (typeof arg === 'string') ||
      (typeof arg === 'boolean'));
  }
}

export interface FactoryMessageResponse {
  /**
   * Message Type, number
   */
  type : number;

  /**
   * Data Part from Socket Response
   */
  data: any;

  /**
   * The value of the factory getMessage()
   */
   message: any;

    /**
     * Message converted via Proto Object toObject() Method
     */
    obj: any;

    /**
     * The original Message
     */
    source: any;
  }

