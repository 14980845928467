import React, { createRef, useState } from 'react';
import { makeStyles, InputAdornment, TextField } from '@pcweb/controls';
import { IconButton, Icon } from '@pcweb/controls';
import { useTranslate } from '@pcweb/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor                                     : theme.palette.background.paper,
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  input: {
    borderRadius        : theme.dimensions.borderRadiusInput,
    backgroundColor     : theme.palette.background.paper,
    '&:-webkit-autofill': {
      borderRadius: 0
    }
  },
  inputMarginDense: {
    paddingTop     : '8.5px',
    paddingBottom: '8.5px',
  },
  notchedOutline: {
    borderColor: theme.palette.secondary.main,
    transition : theme.transitions.create('border-color'),
    '&:hover'  : {
      borderColor: theme.palette.primary.main,
    }
  },
  focused: {
    '& $notchedOutline': {
      borderWidth: '1px !important',
      borderColor: theme.palette.primary.main + '!important',
    },
  },
  disabled: {},
  error   : {},
}));

const SearchInputField = props => {
  const ref = createRef();
  const classes = useStyles();
  const [value, setValue] = useState(props.value);
  const translate = useTranslate();

  return (
    <TextField
      {...props}
      type="search"
      value={value}
      onChange={e => { setValue(e.target?.value); props.onChange(e); }}
      label={props.label}
      placeholder={props.name}
      color="secondary"
      classes={{ ...props.classes }}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        classes: {
          root            : classes.root,
          input           : classes.input,
          inputMarginDense: classes.inputMarginDense,
          notchedOutline  : classes.notchedOutline,
          focused         : classes.focused,
          disabled        : classes.disabled,
          error           : classes.error
        },
        startAdornment: (
          <InputAdornment position="start" aria-label="Search">
            <Icon style={{marginTop: 2}}>search</Icon>
          </InputAdornment>
        ),
        endAdornment: (value?.length > 0 &&
          <InputAdornment position="end">
            <IconButton
              edge="end"
              size="small"
              onClick={e => {
                setValue('');
                props.onChange(e);
              }}
              aria-label={translate('Leeren')}
              title={translate('Leeren')}
            >
              <Icon>clear</Icon>
            </IconButton>
          </InputAdornment>
        ),
      }}
      margin="dense"
      variant="outlined"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      ref={ref}
    ></TextField>
  );
};

export default SearchInputField;
