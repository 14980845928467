export const MessengerChannelKind = {
  Nothing: 0,
  Voice  : 1,
  Fax    : 2,
  Sms    : 3,
  Email  : 4,
  Letter : 6
};

export const MessengerChannelKindName = [
  undefined,
  undefined,
  'addrfax',
  'addrsms',
  'addremail',
  undefined,
  'addrletter?', //TODO
];



export const MessengerMode = {
  Inactive : 0,
  Manually : 1,
  Automatic: 2
};

export const MessengerChannelTemplateState = {
  Nothing         : -1,
  Ok              : 0,
  InvalidId       : 1,
  InvalidRequest  : 2,
  NoTextFile      : 3,
  OtherFailure    : 4,
  InvalidUsage    : 5,
  OkButDeactivated: 6
};