import clsx from 'clsx';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Button as MuiButton } from '@material-ui/core';
import React, { useState, useRef } from 'react';
import { Icon } from '..';
import { makeStyles } from '../styles';
const useStyles = makeStyles(
  theme => ({
    buttonStyle: {
      '&:before': {
        transition          : theme.transitions.create('box-shadow'),
        content             : props => props.active ? '""' : '""',
        position            : props => props.active ? 'absolute' : 'relative',
        backgroundColor     : props => props.active ? 'transparent' : 'transparent',
        bottom              : props => props.active ? '-10px' : 0,
        height              : props => props.active ? 10 : 0,
        width               : props => props.active ? 5 : 0,
        borderTopRightRadius: props => props.active ? theme.dimensions.borderRadiusPanel : 0,
        boxShadow           : props => props.active ? `0 -4px 0 0 ${theme.palette.neutral[900]}` : 'none' ,
        right               : props => props.active ? 0 : 0
      },
      '&:after': {
        transition             : theme.transitions.create('box-shadow'),
        content                : props => props.active ? '""' : '""',
        position               : props => props.active ? 'absolute' : 'relative',
        backgroundColor        : props => props.active ? 'transparent' : 'transparent',
        height                 : props => props.active ? 10 : 0,
        width                  : props => props.active ? 5 : 0,
        borderBottomRightRadius: props => props.active ? theme.dimensions.borderRadiusPanel : 0,
        boxShadow              : props => props.active ? `0 4px 0 0 ${theme.palette.neutral[900]}` : 'none' ,
        right                  : props => props.active ? 0 : 0
      },
      [theme.breakpoints.down('xs')]: {
        margin   : `0 0 ${theme.dimensions.headerSpacingSmall} 0`,
        '&:after': {
          bottom: props => props.active ? 20 : 0,
        },
      },
      [theme.breakpoints.up('sm')]: {
        margin   : `0 0 ${theme.dimensions.headerSpacingSmall} 0`,
        '&:after': {
          bottom: props => props.active ? 20 : 0,
        },
      },
      [theme.breakpoints.up('md')]: {
        margin   : `0 0 ${theme.dimensions.headerSpacingSmall} 0`,
        '&:after': {
          bottom: props => props.active ? 36 : 0,
        },
      },
      [theme.breakpoints.up('lg')]: {
        margin   : `0 0 ${theme.dimensions.headerSpacingSmall} 0`,
        '&:after': {
          bottom: props => props.active ? 40 : 0,
        },
        '& + &': {
          marginRight: 0,
        },
      },
      [theme.breakpoints.up('xl')]: {
        margin   : `0 0 ${theme.dimensions.headerSpacing} 0`,
        '&:after': {
          bottom: props => props.active ? 55 : 0,
        },
      },
      [theme.breakpoints.up('xxl')]: {
        margin   : `0 0 ${theme.dimensions.headerSpacing} 0`,
        '&:after': {
          bottom: props => props.active ? 60 : 0,
        },
      },
      float          : 'left',
      cursor         : 'pointer',
      padding        : '5px',
      borderRadius   : `${theme.dimensions.borderRadiusPanel} 0 0 ${theme.dimensions.borderRadiusPanel}`,
      minWidth       : 0,
      height         : 'auto',
      position       : 'relative',
      margin         : `0 0 ${theme.dimensions.headerSpacing} 0`,
      opacity        : props => (props.disabled === true ? '.6' : '1'),
      backgroundColor: props => {
        if (props.popoverOpened === true) return theme.palette.neutral[100];
        else if (props.bright === true && props.active === true)
          return theme.palette.neutral[900];
        else if (props.bright === true) return alpha(theme.palette.neutral.dark, 0.2);
        return alpha(theme.palette.neutral[100], 0.2);
      },
      color: props => {
        return props.popoverOpened === true || props.active === true || props.dark === true
          ? theme.palette.blue.dark
          : theme.palette.common.white;
      },
      '&:hover': {
        // transition: theme.transitions.create('box-shadow'),
        '&:before': {
          transition          : theme.transitions.create('box-shadow'),
          content             : props => props.active ? '""' : '""',
          position            : props => props.active ? 'absolute' : 'relative',
          backgroundColor     : props => props.active ? 'transparent' : 'transparent',
          bottom              : props => props.active ? '-10px' : 0,
          height              : props => props.active ? 10 : 0,
          width               : props => props.active ? 5 : 0,
          borderTopRightRadius: props => props.active ? theme.dimensions.borderRadiusPanel : 0,
          boxShadow           : props => props.active ? `0 -4px 0 0 ${theme.palette.blue[900]}` : 'none' ,
          right               : props => props.active ? 0 : 0
        },
        '&:after': {
          transition             : theme.transitions.create('box-shadow'),
          content                : props => props.active ? '""' : '""',
          position               : props => props.active ? 'absolute' : 'relative',
          backgroundColor        : props => props.active ? 'transparent' : 'transparent',
          height                 : props => props.active ? 10 : 0,
          width                  : props => props.active ? 5 : 0,
          borderBottomRightRadius: props => props.active ? theme.dimensions.borderRadiusPanel : 0,
          boxShadow              : props => props.active ? `0 4px 0 0 ${theme.palette.blue[900]}` : 'none' ,
          right                  : props => props.active ? 0 : 0,
        },
        [theme.breakpoints.down('xs')]: {
          margin   : `0 0 ${theme.dimensions.headerSpacingSmall} 0`,
          '&:after': {
            bottom: props => props.active ? 20 : 0,
          },
        },
        [theme.breakpoints.up('sm')]: {
          margin   : `0 0 ${theme.dimensions.headerSpacingSmall} 0`,
          '&:after': {
            bottom: props => props.active ? 20 : 0,
          },
        },
        [theme.breakpoints.up('md')]: {
          margin   : `0 0 ${theme.dimensions.headerSpacingSmall} 0`,
          '&:after': {
            bottom: props => props.active ? 36 : 0,
          },
        },
        [theme.breakpoints.up('lg')]: {
          margin   : `0 0 ${theme.dimensions.headerSpacingSmall} 0`,
          '&:after': {
            bottom: props => props.active ? 40 : 0,
          },
          '& + &': {
            marginRight: 0,
          },
        },
        [theme.breakpoints.up('xl')]: {
          margin   : `0 0 ${theme.dimensions.headerSpacing} 0`,
          '&:after': {
            bottom: props => props.active ? 55 : 0,
          },
        },
        [theme.breakpoints.up('xxl')]: {
          margin   : `0 0 ${theme.dimensions.headerSpacing} 0`,
          '&:after': {
            bottom: props => props.active ? 60 : 0,
          },
        },
        backgroundColor: props =>
          props.bright === true
            ? theme.palette.blue[900]
            : alpha(theme.palette.neutral[100], 0.8),
        color: props =>
          props.bright === true ? theme.palette.common.white : theme.palette.neutral[900],
      },
      '& > span > div': {
        padding                                : 5,
        textAlign                              : 'center',
        cursor                                 : 'pointer',
        display                                : 'flex',
        alignItems                             : 'center',
        flexDirection                          : 'column',
        justifyContent                         : 'center',
        [theme.breakpoints.down('xs')]: {
          minWidth: 22,
          height  : 22,
        },
        [theme.breakpoints.up('sm')]: {
          minWidth: 26,
          height  : 26,
        },
        [theme.breakpoints.up('md')]: {
          minWidth: 26,
          height  : 26,
        },
        [theme.breakpoints.up('lg')]: {
          minWidth: 30,
          height  : 30,
        },
        [theme.breakpoints.up('xl')]: {
          minWidth: 45,
          height  : 45,
        },
        [theme.breakpoints.up('xxl')]: {
          minWidth: 55,
          height  : 50,
        },
        '& > span': {
          textOverflow                           : 'ellipsis',
          overflow                               : 'hidden',
          display                                : 'none',
          [theme.breakpoints.down('md')]: {
            width: 20,
          },
          [theme.breakpoints.up('lg')]: {
            width: 25,
          },
          [theme.breakpoints.up('xl')]: {
            width: 34,
          },
          [theme.breakpoints.up('xxl')]: {
            width  : 46,
            display: 'block'
          },
        },
      },
    },
    chevronStyle: {
      fontSize                               : '1.2em',
      position                               : 'absolute',
      transition                             : theme.transitions.create('background-color'),
      [theme.breakpoints.down('xl')]: {
        right: 2,
      },
      [theme.breakpoints.up('xxl')]: {
        right: 5
      },
    },
    iconStyle: {
      textShadow                       : theme.dimensions.textShadow,
      [theme.breakpoints.down('md')]: {
        fontSize  : 16,
        lineHeight: 1,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize  : 16,
        lineHeight: 1,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize   : 18,
        lineHeight: 1.5,
      }
    },
    labelStyle: {
      font         : `${theme.typography.fontWeightMedium} ${theme.typography.size.xs} Din`,
      whiteSpace   : 'nowrap',
      letterSpacing: '1px',
      userSelect   : 'none',
      transition   : theme.transitions.create('background-color'),
      textTransform: 'uppercase',
      textShadow   : theme.dimensions.textShadow,
    },
    imageContainer: {
      display                                : 'flex',
      alignItems                             : 'center',
      justifyContent                         : 'center',
      [theme.breakpoints.down('md')]: {
        width    : 16,
        minWidth : 16,
        height   : 22,
        minHeight: 22
      },
      [theme.breakpoints.up('lg')]: {
        width    : 16,
        minWidth : 16,
        height   : 22,
        minHeight: 22
      },
      [theme.breakpoints.up('xl')]: {
        width        : 18,
        minWidth     : 18,
        height       : 28,
        minHeight    : 28,
        paddingBottom: 2
      },
    },
    imageStyle: {
      maxHeight: '70%',
      width    : 'auto',
      filter   : `drop-shadow(${theme.dimensions.textShadow})`
    }
  }),
  { name: 'VerticalTab' },
);

const Button = React.forwardRef((props, ref) => {
  const classes = useStyles(props);

  const passProps = { ...props };
  delete passProps.bright;
  delete passProps.dark;
  delete passProps.active;
  delete passProps.popoverOpened;
  delete passProps.chevron;
  delete passProps.drawerPaperProps;

  return (
    <MuiButton ref={ref} className={clsx(classes.root, classes.buttonStyle, 'header-button')} {...passProps}>
      {props.children}
    </MuiButton>
  );
});

/**
 *
 * VerticalTab
 *
 * Callbacks
 * onClick()                    => triggered, if the button pushed / returns {event}
 * onChevronClick()             => triggered, if the chevron pushed / returns {event}
 * onStateChanged()             => returns the current open State {boolean}
 *
 * Key Events
 * onKeyEscape()                => triggered if Escape was pushed : default behaviour: close popover
 * onKeyEnter()                 => triggered if Enter was pushed
 * onKeyArrow()                 => returns {'up','down','left' or 'right'}
 * onKeyDown()                  => returns the event
 *
 * Properties
 * open {boolean}               => enable/disable the button from outside
 * chevronSize{float}           => the size of the chevron button ( default: 0.7 )
 * chevron {boolean}
 * noPadding {boolean}
 * bright {boolean}
 * disabled {boolean}
 * label {string}
 * icon {string}
 * anchorElement
 * anchorOrigin {object}        => default { vertical: 'bottom', horizontal: 'left' }
 * transformOrigin {object}     => default { vertical: 'top', horizontal: 'left' }
 * popoverClass {string}
 * data-element {string}
 */

const VerticalTab = props => {
  const [state, setState] = useState({ open: false });
  const _buttonRef = useRef(null);
  const classes = useStyles(props);

  function _isOpen() {
    return props.open != 'undefined' ? props.open : state.open;
  }

  function _open() {
    if (props.onStateChanged) props.onStateChanged(true);
    setState({ ...state, open: true });
  }

  function _close() {
    if (props.onStateChanged) props.onStateChanged(false);
    setState({ ...state, open: false });
  }

  function _toggle() {
    props.children && _isOpen() === false ? _open() : _close();
  }

  function _onKeyDown(event) {
    switch (event.key) {
    case 'Escape': // 27
      props.onKeyEscape ? props.onKeyEscape() : _close();
      break;
    case 'Enter': // 13
      if (props.onKeyEnter) {
        props.onKeyEnter();
      }
      if (!_isOpen()) {
        _open();
        event.stopPropagation();
        event.preventDefault();
      }
      break;
    case 'ArrowUp':
      if (props.onKeyArrow) {
        _onKeyArrow('up');
      }
      break;
    case 'ArrowDown':
      if (props.onKeyArrow) {
        _onKeyArrow('down');
      }
      break;
    case 'ArrowLeft':
      if (props.onKeyArrow) {
        _onKeyArrow('left');
      }
      break;
    case 'ArrowRight':
      if (props.onKeyArrow) {
        _onKeyArrow('right');
      }
      break;
    case ' ': // Keycode 32
      _onKeySpace();
      break;
    }

    if (props.onKeyDown) {
      props.onKeyDown(event);
    }
  }

  function _onKeySpace() {
    if (props.onKeySpace) {
      props.onKeySpace();
    }

    if (!_isOpen()) {
      _open();
      // Prevent sending the Key to the popover, if it was closed
      event.stopPropagation();
      event.preventDefault();
    }
  }

  function _onKeyArrow(dir) {
    if (props.onKeyArrow) {
      props.onKeyArrow(dir);
    }
  }

  //onClick event is triggered on 'mouse click' and 'enter' or 'space' hits
  //e.detail returns '1' when is triggered precisely by 'mouse click'
  function _onClick(e) {
    if (!e.detail) return;
    if (
      props.chevron &&
      _isChevronClick(
        e.clientX,
        e.currentTarget.getBoundingClientRect(),
        e.currentTarget.offsetWidth,
        props.chevronSize ? props.chevronSize : 0.7,
      )
    ) {
      props.onChevronClick ? props.onChevronClick(e) : _toggle();
    } else {
      props.onClick ? props.onClick(e) : _toggle();
    }
  }

  function _isChevronClick(clientX, buttonRect, buttonOffsetWidth, chevronSize) {
    return clientX - buttonRect.left >= buttonOffsetWidth * chevronSize;
  }

  function _getIconElement(value) {
    if (typeof value === 'string') {
      return <Icon className={clsx(classes.iconStyle, 'icon-style')}>{value}</Icon>;
    } else if (value && value.props && value.props.src) {
      return (
        <div className={clsx(classes.imageContainer, 'image-container')}>
          <img src={value.props.src} className={classes.imageStyle} />
        </div>
      );
    }
    return <div />;
  }

  return (
    <div>
      <Button
        classes={{ ...props.classes }}
        data-element={props['data-element']}
        bright={props.bright}
        dark={props.dark}
        active={props.active}
        chevron={props.chevron}
        title={props.label}
        aria-label={props.label}
        onClick={_onClick}
        popoverOpened={_isOpen()}
        ref={_buttonRef}
        disabled={props.disabled}
        onKeyDown={_onKeyDown}
        role="tab"
        aria-selected={props.active}
      >
        <div>
          {_getIconElement(props.icon)}
          {props.label && (
            <span className={clsx(classes.labelStyle, 'label-style')}>{props.label}</span>
          )}
          {props.chevron && (
            <Icon
              className={classes.chevronStyle}
              data-element="header-button.expand-chevron"
            >
              {_isOpen() ? 'expand_less' : 'expand_more'}
            </Icon>
          )}
        </div>
      </Button>
    </div>
  );
};

export default VerticalTab;
