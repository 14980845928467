import hotkeys from 'hotkeys-js';
import HotkeyList from './HotkeyList';
import Events from '@core/events';
import Actions from '@core/actions';

type HotkeyAction = {
  action: string;
  name: string;
  elementName?: any;
  syntheticEventType?: typeof KeyboardEvent | MouseEvent;
};

/**
 *
 * Hotkey Plugin
 * ud/pcweb-hotkeys
 *
 */
export default class Package {
  private _sdk: any;
  private _hotkeyList: any = [];
  private _hotkeyFeature = new Map<number, HotkeyAction>();
  private _initialized: boolean = false;

  constructor() {
    this._sdk = null;
  }

  run(sdk: any): void {
    this._sdk = sdk;
    this._init();

    Events.on('sdk.me.config.received', () => this._init());
  }

  public get all() {
    return this._hotkeyList;
  }

  public get buildInfo() {
    const buildTime = process.env.BUILD_TIMESTAMP;
    const buildHash = process.env.BUILD_REVISION;
    const buildVersion = process.env.BUILD_VERSION;
    return {
      buildHash,
      buildTime,
      buildVersion,
    };
  }

  private _init() {
    if (this._initialized === true || !this._sdk.customer?.hotkeyList?.length) return;

    this._hotkeyList = this._sdk.customer.hotkeyList || [];

    const { translate } = this._sdk.language;

    this._hotkeyFeature.set(1, { action: 'sdk.auth.me.ready', name: translate('Bereit') });
    this._hotkeyFeature.set(2, { action: 'sdk.auth.me.pause', name: translate('Pause') });
    this._hotkeyFeature.set(3, { action: 'sdk.auth.me.extend-wrapup', name: translate('Verlängern') });
    this._hotkeyFeature.set(4, { action: 'sdk.calls.hold', name: translate('Halten') });
    this._hotkeyFeature.set(5, { action: 'engage.client.ui.component.focus', name: translate('Weiterleiten'), elementName: 'forward-call-button', syntheticEventType: KeyboardEvent });
    this._hotkeyFeature.set(6, { action: 'sdk.calls.mute', name: translate('Stumm_schalten') });
    this._hotkeyFeature.set(7, { action: 'sdk.calls.hangup', name: translate('Auflegen') });
    this._hotkeyFeature.set(10, { action: 'engage.client.ui.component.focus', name: translate('Telefonbuch'), elementName: 'acd/component-contact-book' });
    this._hotkeyFeature.set(11, { action: 'engage.client.ui.component.focus', name: translate('Wahlwiederholungsliste'), elementName: 'acd/component-call-history' });
    this._hotkeyFeature.set(12, { action: 'engage.client.ui.component.focus', name: translate('Anruflisten'), elementName: 'acd/component-call-history' });
    this._hotkeyFeature.set(15, { action: 'engage.client.ui.component.focus', name: translate('PickContact'), elementName: 'acd/component-pick-list' });
    this._hotkeyFeature.set(18, { action: 'engage.client.ui.component.focus', name: translate('Codierung'), elementName: 'acd/component-call-reasons' });
    this._hotkeyFeature.set(100, { action: 'sdk.auth.logout', name: translate('Logout') });

    this._hotkeyList.map(hotkey => {
      hotkey.keyString = this._getKeyString(parseInt(hotkey.keyid,10), parseInt(hotkey.shift, 10));
      hotkey.actionId = parseInt(hotkey.keycommand,10);
      hotkey.translatedName = this._hotkeyFeature.has(hotkey.actionId)
        ? this._hotkeyFeature.get(hotkey.actionId).name
        : this._sdk.language.translate('Not_Available');
      hotkey.translatedKey = this._getTranslatedKey(hotkey.keyString);
    });

    this._sdk.debug('core/hotkeys').trace('hotkeys initialized', this._getHotkeyDebug());

    hotkeys(this._getHotkeyList(), (event, handler) => {
      event.preventDefault();

      const findAction = this._hotkeyList.find(k => k.keyString === handler.key);
      if (findAction) {
        this._onHotkeyPushed(findAction.actionId);
      }
    });

    this._initialized = true;
  }

  private _onHotkeyPushed(actionId: number): void {
    if (this._hotkeyFeature.has(actionId) && !!Actions) {
      const { action, elementName, syntheticEventType } = this._hotkeyFeature.get(actionId);
      Actions.invoke(action, { reason: 'hotkeys', ...{ elementName, syntheticEventType }});
    }
  }

  private _getTranslatedKey(key): string {
    return key
      .replace('ctrl+', this._sdk.language.translate('Strg_Plus'))
      .replace('shift+', this._sdk.language.translate('Umschalt_Plus'))
      .replace('alt+', this._sdk.language.translate('Alt_Plus'))
      .replace('+f', '+F');
  }

  private _getKeyString(acdKeyId: number, acdShift: number): string {
    let keyString = '';
    let shift = acdShift;

    const keys = [
      { v: 4, key: 'alt' },
      { v: 2, key: 'ctrl' },
      { v: 1, key: 'shift' },
    ];

    keys.forEach(k => {
      if (shift >= k.v) {
        shift -= k.v;
        keyString += `${k.key}+`;
      }
    });

    return `${keyString}f${acdKeyId - 111}`;
  }

  private _getHotkeyList(): string {
    let keyCodes = '';
    this._hotkeyList.forEach(hotkey => {
      if (keyCodes) keyCodes += ',';
      keyCodes += `${hotkey.keyString}`;
    });
    return keyCodes;
  }

  private _getHotkeyDebug(): object {
    let hotkeyDebug = { hotkeyCount: this._hotkeyList.length };
    this._hotkeyList.forEach(hotkey => {
      const currentHotkey = this._hotkeyFeature.get(hotkey.actionId);
      hotkeyDebug[hotkey.keyString] = currentHotkey
        ? currentHotkey.action
        : `missing:${hotkey.keycommand}`;
    });
    return hotkeyDebug;
  }

  get meta() {
    return {
      icon         : 'chat',
      displayName: 'Tastenkombination',
      type       : 'react'
    };
  }
}

export {
  HotkeyList
};