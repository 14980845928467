import React from 'react';
import clsx from 'clsx';
import { makeStyles, Collapse } from '@pcweb/controls';
import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';

export const MessengerComponent = observer(({ plugin }) => {
  const { messenger, sdk, gui } = plugin;
  const messengerJob = messenger?.dataset?.activeMessengerJob;
  const { translate } = sdk.language;
  const {
    Icon,
    PrimaryButton,
    // SecondaryButton,
    Dialog,
    DialogContent,
    TitleText,
    DialogActions,
    TextInputField,
    TextArea,
    FormControlLabel,
    Checkbox,
    SelectComponentOld,
  } = gui.ui;
  const classes = useStyles();

  const handleClose = (_, reason) => {
    if (reason !== 'backdropClick') {
      messenger.closeDialog();
      messengerJob?.finish(false);
    }
  };

  const handleSend = () => {
    messenger.closeDialog();
    messengerJob?.finish(true);
  };


  const handleInputReceiverChange = (value, event) => {
    if (event.action === 'input-blur' || event.action === 'menu-close') {
      return false;
    }
    if (event.action === 'clear') {
      messengerJob?.setReceiverAddress('');
      return;
    }
    messengerJob?.setReceiverAddress(value);
  };

  const translateMessageLabel = (pos: number) => {
    if(pos === 1) return translate('1_Nachrichtenversand');
    if(pos === 2) return translate('2_Nachrichtenversand');
    return translate(`${pos}. Nachrichtenversand`);
  };

  return (
    <Dialog open={messenger.dialogOpen} onClose={handleClose}>
      <div className={classes.container}>
        <TitleText onClose={handleClose}>{translate('Messenger')}</TitleText>
        <DialogContent style={{ padding: 0, borderTop: 'none' }}>
          <div className={classes.container}>
            { messengerJob && (<div className={classes.headerBar}>
              <span className={classes.header}>{translateMessageLabel(messengerJob?.pos || 1)}</span>
              <div className={classes.flex}>
                <span
                  className={classes.header}
                >{`${messengerJob?.message?.length}/${messengerJob?.messageMaxLength}`}</span>
                <div
                  className={clsx(
                    classes.badge,
                    messengerJob?.messageValid
                      ? classes.badgeGreen
                      : classes.badgeRed
                  )}
                />
              </div>
            </div>) }
            <div className={classes.body}>
              {messenger?.jobSelectionAllowed && (
                <SelectComponentOld
                  name="costcenter"
                  visible={true}
                  options={messenger.allJobs}
                  valueName="id"
                  displayName="name"
                  data-element="messenger.job-select-component"
                  onChange={({ value }: { value:number}) => messenger.selectJob(value)}
                  disabled={false}
                  isSearchable={false}
                />
              )}
              <div className={classes.flex}>
                <div
                  className={clsx(
                    classes.option,
                    messengerJob?.isEmail ?
                      classes.optionActivated :
                      messengerJob?.isChannelSwitchable ? classes.optionSelectable : classes.optionDeactivated
                  )}
                  onClick={() => messengerJob?.setChannelEmail()}
                >
                  <Icon className={classes.icon}>mail</Icon>
                  <span className={classes.title}>{translate('E_Mail')}</span>
                </div>
                { <div
                  className={clsx(
                    classes.option,
                    messengerJob?.isFax ?
                      classes.optionActivated :
                      messengerJob?.isChannelSwitchable ? classes.optionSelectable : classes.optionDeactivated
                  )}
                  onClick={() => messengerJob?.setChannelFax()}
                >
                  <Icon className={classes.icon}>print</Icon>
                  <span className={classes.title}>{translate('Fax')}</span>
                </div> }
                <div
                  className={clsx(
                    classes.option,
                    messengerJob?.isSms
                      ? classes.optionActivated
                      : messengerJob?.isChannelSwitchable ? classes.optionSelectable : classes.optionDeactivated
                  )}
                  onClick={() => messengerJob?.setChannelSms()}
                >
                  <Icon className={classes.icon}>textsms</Icon>
                  <span className={classes.title}>{translate('SMS')}</span>
                </div>
                { <div
                  className={clsx(
                    classes.option,
                    messengerJob?.isLetter  ?
                      classes.optionActivated :
                      messengerJob?.isChannelSwitchable ? classes.optionSelectable : classes.optionDeactivated
                  )}
                  onClick={() => messengerJob?.setChannelLetter()}
                  style={{ marginRight: 0 }}
                >
                  <Icon className={classes.icon}>insert_drive_file</Icon>
                  <span className={classes.title}>{translate('Brief')}</span>
                </div> }
              </div>
              {!messengerJob?.isLetter && (<SelectComponentOld
                disabled={!messengerJob?.messageReceiverEditable}
                isSearchable={true}
                required={true}
                options={messengerJob?.messageShortDials || []}
                valueName={messengerJob?.channelId}
                displayName="name"
                inputValue={messengerJob?.receiverAddress}
                helperText={
                  !messengerJob?.receiverValid
                    ? translate('Kein_gültiges_Format')
                    : ''
                }
                openMenuOnClick={false}
                data-element={'messenger-receiver'}
                placeholder={translate('Empfänger')}
                onInputChange={handleInputReceiverChange}
                onChange={(option, event) =>
                  handleInputReceiverChange(option ? option.value : null, event)
                }
                isClearable={true}
                autoFocus={true}
                className={classes.textinput}
                // Hide the dropdown indicator when no shortdial is present
                components={
                  messengerJob?.messageShortDials?.length > 0
                    ? {}
                    : { DropdownIndicator: () => null }
                }
              />)}

              {messengerJob?.isEmail && (
                <TextInputField
                  disabled={!messengerJob?.messageEditable}
                  name={translate('Betreff')}
                  placeholder={translate('Betreff')}
                  style={{ width: '100%' }}
                  value={messengerJob?.subject}
                  required={true}
                  onChange={(event) =>
                    messengerJob?.setSubject(event.target.value)
                  }
                  className={classes.textinput}
                />
              )}
              {messengerJob?.isLetter && (
                <div className={classes.flex} style={{ justifyContent: 'space-between'}}>
                  <div className={classes.flex} style={{ flexDirection: 'column', width: '52.22222%'}}>
                    <TextInputField
                      disabled={!messengerJob?.messageEditable}
                      name={translate('Bezeichner')}
                      placeholder={translate('Bezeichner')}
                      style={{ width: '100%' }}
                      //       value={messengerJob?.getLetterReceiverParam(0)}
                      error={!messengerJob?.letterBox.isFieldValid(0)}
                      required={true}
                      onChange={action((event) => messengerJob?.letterBox.setValue(0, event.target.value))}
                      className={classes.textinput}
                    />
                    <TextInputField
                      disabled={!messengerJob?.messageEditable}
                      name={translate('Anrede')}
                      placeholder={translate('Anrede')}
                      style={{ width: '100%' }}
                      //   value={messengerJob?.getLetterReceiverParam(1)}
                      error={!messengerJob?.letterBox.isFieldValid(1)}
                      required={true}
                      onChange={action((event) => messengerJob?.letterBox.setValue(1, event.target.value))}
                      className={classes.textinput}
                    />
                    <TextInputField
                      disabled={!messengerJob?.messageEditable}
                      name={translate('Name1')}
                      placeholder={translate('Name1')}
                      style={{ width: '100%' }}
                      //     value={messengerJob?.getLetterReceiverParam(2)}
                      error={!messengerJob?.letterBox.isFieldValid(2)}
                      required={true}
                      onChange={action((event) => messengerJob?.letterBox.setValue(2, event.target.value))}
                      className={classes.textinput}
                    />
                    <TextInputField
                      disabled={!messengerJob?.messageEditable}
                      name={translate('Name2')}
                      placeholder={translate('Name2')}
                      style={{ width: '100%' }}
                      //   value={messengerJob?.getLetterReceiverParam(3)}
                      error={!messengerJob?.letterBox.isFieldValid(3)}
                      required={true}
                      onChange={action((event) => messengerJob?.letterBox.setValue(3, event.target.value))}
                      className={classes.textinput}
                    />
                    <TextInputField
                      disabled={!messengerJob?.messageEditable}
                      name={translate('Name3')}
                      placeholder={translate('Name3')}
                      style={{ width: '100%' }}
                      //   value={messengerJob?.getLetterReceiverParam(4)}
                      error={!messengerJob?.letterBox.isFieldValid(4)}
                      required={true}
                      onChange={action((event) => messengerJob?.letterBox.setValue(4, event.target.value))}
                      className={classes.textinput}
                    />
                  </div>

                  <div className={classes.flex} style={{ flexDirection: 'column', width: '44.44444%'}}>
                    <TextInputField
                      disabled={!messengerJob?.messageEditable}
                      name={translate('Straße')}
                      placeholder={translate('Straße')}
                      style={{ width: '100%' }}
                      // value={messengerJob?.getLetterReceiverParam(5)}
                      error={!messengerJob?.letterBox.isFieldValid(5)}
                      required={true}
                      onChange={action((event) => messengerJob?.letterBox.setValue(5, event.target.value))}
                      className={classes.textinput}
                    />
                    <div className={classes.flex} style={{ justifyContent: 'space-between'}}>
                      <TextInputField
                        disabled={!messengerJob?.messageEditable}
                        name={translate('Nr.')}
                        placeholder={translate('Nr.')}
                        style={{ width: '48%' }}
                        // value={messengerJob?.getLetterReceiverParam(6)}
                        error={!messengerJob?.letterBox.isFieldValid(6)}
                        required={true}
                        onChange={action((event) => messengerJob?.letterBox.setValue(6, event.target.value))}
                        className={classes.textinput}
                      />
                      <TextInputField
                        disabled={!messengerJob?.messageEditable}
                        name={translate('PLZ')}
                        placeholder={translate('PLZ')}
                        style={{ width: '48%' }}
                        //  value={messengerJob?.getLetterReceiverParam(7)}
                        error={!messengerJob?.letterBox.isFieldValid(7)}
                        required={true}
                        onChange={action((event) => messengerJob?.letterBox.setValue(7, event.target.value))}
                        className={classes.textinput}
                      />
                    </div>
                    <TextInputField
                      disabled={!messengerJob?.messageEditable}
                      name={translate('Ort')}
                      placeholder={translate('Ort')}
                      style={{ width: '100%' }}
                      // value={messengerJob?.getLetterReceiverParam(8)}
                      error={!messengerJob?.letterBox.isFieldValid(8)}
                      required={true}
                      onChange={action((event) => messengerJob?.letterBox.setValue(8, event.target.value))}
                      className={classes.textinput}
                    />
                    <TextInputField
                      disabled={!messengerJob?.messageEditable}
                      name={translate('Land')}
                      placeholder={translate('Land')}
                      style={{ width: '100%' }}
                      //    value={messengerJob?.getLetterReceiverParam(9)}
                      error={!messengerJob?.letterBox.isFieldValid(9)}
                      required={true}
                      onChange={action((event) => messengerJob?.letterBox.setValue(9, event.target.value))}
                      className={classes.textinput}
                    />
                    <TextInputField
                      disabled={!messengerJob?.messageEditable}
                      name={translate('Kürzel')}
                      placeholder={translate('Kürzel')}
                      style={{ width: '100%' }}

                      //    value={messengerJob?.getLetterReceiverParam(10)}
                      error={!messengerJob?.letterBox.isFieldValid(10)}
                      required={true}
                      onChange={action((event) => messengerJob?.letterBox.setValue(10, event.target.value))}
                      className={classes.textinput}
                    />
                  </div>
                </div>
              )}
              <TextArea
                disabled={!messengerJob?.messageEditable}
                name={translate('Nachricht')}
                placeholder={translate('Nachricht')}
                style={{ width: '100%' }}
                value={messengerJob?.message}
                inputProps={{ maxLength: messengerJob?.messageMaxLength}}
                onChange={(event) => {
                  runInAction(() =>
                    messengerJob?.setMessage(event.target.value)
                  );
                }}
              />
              {messengerJob?.isEmail && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="PDF"
                        checked={messengerJob?.pdfChecked}
                        onChange={() => messengerJob?.togglePdfChecked()}
                      />
                    }
                    label={translate('E_Mail_Anhang_als_PDF-Dokument')}
                  />
                  <Collapse
                    in={messengerJob?.pdfChecked}
                    timeout="auto"
                    unmountOnExit
                  >
                    <TextArea
                      name={translate('PDF')}
                      placeholder={translate('PDF')}
                      style={{ width: '100%' }}
                      value={messengerJob?.pdfMessage}
                      inputProps={{
                        maxLength: messengerJob?.messageMaxLength,
                      }}
                      onChange={(event) =>
                        messengerJob?.setPdfMessage(event.target.value)
                      }
                    />
                  </Collapse>
                </>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {/* <SecondaryButton onClick={handleClose}>
              <Icon>refresh</Icon> {translate('Aktualisieren')}
            </SecondaryButton>
            <SecondaryButton onClick={handleClose}>
              <Icon>refresh</Icon> {translate('Vorlage_neu_laden')}
            </SecondaryButton> */}
          <PrimaryButton
            data-element={'messenger.send'}
            onClick={handleSend}
            disabled={!messengerJob?.jobValid}
          >
            <Icon>send</Icon> {translate('Senden')}
          </PrimaryButton>
        </DialogActions>
      </div>
    </Dialog>
  );
});

export default MessengerComponent;

const useStyles = makeStyles(
  (theme) => ({
    container: {
      width: 500,
    },
    headerBar: {
      display          : 'flex',
      alignItems       : 'center',
      justifyContent   : 'space-between',
      width            : '100%',
      height           : theme.spacing(5),
      padding          : theme.spacing(0, 2),
      backgroundColor  : theme.palette.neutral[400],
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.neutral[600],
    },
    header: {
      fontSize  : theme.typography.size.s,
      color     : theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    badge: {
      height      : theme.spacing(1),
      width       : theme.spacing(1),
      borderRadius: '50%',
      marginLeft  : theme.spacing(1),
    },
    badgeGreen: {
      backgroundColor: theme.palette.utility.green.main,
    },
    badgeRed: {
      backgroundColor: theme.palette.utility.red.main,
    },
    flex: {
      display   : 'flex',
      alignItems: 'center',
    },
    body: {
      padding: theme.spacing(2),
    },
    input: {
      width: '50%',
    },
    option: {
      backgroundColor: theme.palette.common.white,
      border         : `1px solid ${theme.palette.secondary.main}`,
      borderRadius   : theme.dimensions.borderRadiusInput,
      display        : 'flex',
      alignItems     : 'center',
      justifyContent : 'center',
      flexDirection  : 'column',
      marginTop      : theme.spacing(1),
      marginRight    : theme.spacing(1.5),
      marginBottom   : theme.spacing(0.5),
      padding        : theme.spacing(1),
      transition     : theme.transitions.create('all'),
      width          : '25%',
    },
    optionActivated: {
      backgroundColor: theme.palette.primary.main,
      borderColor    : theme.palette.primary.main,
      color          : theme.palette.common.white,
      '&:hover'      : {
        cursor: 'default',
      },
    },
    optionSelectable: {
      backgroundColor: theme.palette.secondary.main,
      borderColor    : theme.palette.secondary.main,
      color          : theme.palette.common.white,
      '&:hover'      : {
        backgroundColor: theme.palette.primary.main,
        borderColor    : theme.palette.primary.main,
        color          : theme.palette.common.white,
        cursor         : 'pointer',
      },
    },
    optionDeactivated: {
      pointerEvents  : 'none',
      backgroundColor: theme.palette.grey[300],
      borderColor    : theme.palette.grey[400],
      color          : theme.palette.grey[700],
      '&:hover'      : {
        cursor: 'not-allowed',
      },
    },
    title: {
      fontSize  : theme.typography.size.l,
      fontWeight: theme.typography.fontWeightMedium,
    },
    icon: {
      marginBottom: theme.spacing(0.5),
    },
    textinput: {
      marginBottom: 6,
    },
    textinputHalved: {
      width: '50%'
    }
  }),
  { name: 'Messenger' }
);
