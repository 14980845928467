import AbstractMessageFactory                                 from 'message-factory/lib/AbstractMessageFactory';
import InitialisationResponseFactory                          from 'message-factory/lib/2_InitialisationResponse';
import GeneralResponseFactory                                 from 'message-factory/lib/3_GeneralResponse';
import LoginRequestFactory                                    from 'message-factory/lib/10003_LoginRequest';
import LoginResponseFactory                                   from 'message-factory/lib/10004_LoginResponse';
import ChangeStateFactory                                     from 'message-factory/lib/10006_ChangeState';
import ClientExitRequestFactory                               from 'message-factory/lib/10009_ClientExitRequest';
import AsSmSwitchToServiceMsgFactory                          from 'message-factory/lib/10023_AsSmSwitchToService';
import CampaignConfigReqMsgFactory                            from 'message-factory/lib/10024_CampaignConfigReq';
import CampaignConfigRespMsgFactory                           from 'message-factory/lib/10025_CampaignConfigResp';
import AsVoiceRecStartReqFactory                              from 'message-factory/lib/10030_AsVoiceRecStartReqMsg';
import AsVoiceRecStopReqFactory                               from 'message-factory/lib/10031_AsVoiceRecStopReqMsg';
import AsVoiceRecStatusRespFactory                            from 'message-factory/lib/10032_AsVoiceRecStatusRespMsg';
import UmsGetAllOrdersRequestFactory                          from 'message-factory/lib/10033_UmsGetAllOrdersRequest';
import AsUmsGetAllOrdersRespMsg                               from 'message-factory/lib/10034_UmsGetAllOrdersRespMsg';
import AsNextCallsPreviewStartFactory                         from 'message-factory/lib/10037_AsNextCallsPreviewStart';
import AsNextCallsPreviewStopFactory                          from 'message-factory/lib/10038_AsNextCallsPreviewStop';
import AsNextCallsPreviewMsg                                  from 'message-factory/lib/10039_AsNextCallsPreviewRespMsg';
import PingFactory                                            from 'message-factory/lib/10042_Ping';
import PongFactory                                            from 'message-factory/lib/10043_Pong';
import AsConnectedClientsReqMsgFactory                        from 'message-factory/lib/10044_AsConnectedClientsReqMsg';
import AsConnectedClientsRespMsgFactory                       from 'message-factory/lib/10045_AsConnectedClientsRespMsg';
import StatusRequestFactory                                   from 'message-factory/lib/10048_StatusRequest';
import AsDialerPauseOnRequestFactory                          from 'message-factory/lib/10062_AsDialerPauseOnReqMsg';
import AsDialerPauseOnResponseFactory                         from 'message-factory/lib/10063_AsDialerPauseOnRespMsg';
import AsDialerTakeOverCallRequestFactory                     from 'message-factory/lib/10064_AsDialerTakeOverCallRequest';
import AsDialerTakeOverCallResponseFactory                    from 'message-factory/lib/10065_AsDialerTakeOverCallResponse';
import AsDialerTakeOverCommitRequestFactory                   from 'message-factory/lib/10066_AsDialerTakeOverCommitRequest';
import AsDialerTakeOverCallReconnectRequestFactory            from 'message-factory/lib/10067_AsDialerTakeOverCallReconnectRequest';
import AsCallFlowGetReqMsgFactory                             from 'message-factory/lib/10091_AsCallFlowGetReqMsg';
import AsCallFlowGetRespMsgFactory                            from 'message-factory/lib/10092_AsCallFlowGetRespMsg';
import AsHangUpAgentMsgFactory                                from 'message-factory/lib/10100_AsHangUpAgentMsg';
import DialerAgentCallHistoryRequestFactory                   from 'message-factory/lib/10118_DialerAgentCallHistoryRequest';
import DialerAgentCallHistoryResponseFactory                  from 'message-factory/lib/10119_DialerAgentCallHistoryResponse';
import TextModuleCollectionResponse                           from 'message-factory/lib/10130_TextModuleCollectionResponse';
import LogoutRequestFactory                                   from 'message-factory/lib/11003_LogoutRequest';
import PauseRequestFactory                                    from 'message-factory/lib/11004_PauseRequest';
import TransferBlindRequestFactory                            from 'message-factory/lib/11005_TransferBlindRequest';
import QueryRequestFactory                                    from 'message-factory/lib/11006_QueryRequest';
import TransferRequestFactory                                 from 'message-factory/lib/11007_TransferRequest';
import ReconnectRequestFactory                                from 'message-factory/lib/11008_ReconnectRequest';
import EndCallRequestFactory                                  from 'message-factory/lib/11009_EndCallRequest';
import OnHoldRequestFactory                                   from 'message-factory/lib/11010_OnHoldRequest';
import ChangeExtendedStateFactory                             from 'message-factory/lib/11011_ChangeExtendedState';
import NewCallInfoFactory                                     from 'message-factory/lib/11012_NewCallInfo';
import ReadyRequestFactory                                    from 'message-factory/lib/11014_ReadyRequest';
import CallResultRequestFactory                               from 'message-factory/lib/11015_CallResultRequest';
import WrapUpRequestFactory                                   from 'message-factory/lib/11016_WrapUpRequest';
import MakeOutCallRequestFactory                              from 'message-factory/lib/11017_MakeOutCallRequest';
import AgentsBlockedGroupRequestFactory                       from 'message-factory/lib/11018_AgentsBlockedGroupRequest';
import AgentsBlockedGroupResponseFactory                      from 'message-factory/lib/11019_AgentsBlockedGroupResponse';
import TapiCallsCountInfoFactory                              from 'message-factory/lib/11020_TapiCallsCountInfo';
import NewOutCallInfoResponseFactory                          from 'message-factory/lib/11022_NewOutCallInfoResponse';
import GroupMonitorStartFactory                               from 'message-factory/lib/11023_GroupMonitorStart';
import GroupMonitorDataResponseFactory                        from 'message-factory/lib/11025_GroupMonitorDataResponse';
import AgentMonitorConfigurationRequestFactory                from 'message-factory/lib/11026_AgentMonitorConfigurationRequest';
import AgentMonitorConfigurationResponseFactory               from 'message-factory/lib/11027_AgentMonitorConfigurationResponse';
import AgentMonitorInitDataFactory                            from 'message-factory/lib/11028_AgentMonitorInitData';
import AgentMonitorUpdateDataFactory                          from 'message-factory/lib/11029_AgentMonitorUpdateData';
import AgentMonitorStartFactory                               from 'message-factory/lib/11032_AgentMonitorStart';
import NumberCompletionRequestFactory                         from 'message-factory/lib/11034_NumberCompletionRequest';
import AsPersonalDataGetReqMsgFactory                         from 'message-factory/lib/11035_AsPersonalDataGetReqMsg';
import AsPersonalDataGetRespMsgFactory                        from 'message-factory/lib/11036_AsPersonalDataGetRespMsg';
import AsPersonalDataEditReqMsgFactory                        from 'message-factory/lib/11037_AsPersonalDataEditReqMsg';
import AsPersonalDataAddReqMsgFactory                         from 'message-factory/lib/11038_AsPersonalDataAddReqMsg';
import AsPersonalDataAddRespMsgFactory                        from 'message-factory/lib/11039_AsPersonalDataAddRespMsg';
import HistoryInfoFactory                                     from 'message-factory/lib/11043_HistoryInfo';
import CustomerConfigRequestFactory                           from 'message-factory/lib/11050_CustomerConfigRequest';
import CustomerConfigResponseFactory                          from 'message-factory/lib/11051_CustomerConfigResponse';
import GroupConfigDataRequestFactory                          from 'message-factory/lib/11052_GroupConfigDataRequest';
import GroupConfigDataResponseFactory                         from 'message-factory/lib/11053_GroupConfigDataResponse';
import AgentConfigRequestFactory                              from 'message-factory/lib/11054_AgentConfigRequest';
import AgentConfigResponseFactory                             from 'message-factory/lib/11055_AgentConfigResponse';
import UserStatisticRequestFactory                            from 'message-factory/lib/11056_UserStatisticRequest';
import UserStatisticResponseFactory                           from 'message-factory/lib/11057_UserStatisticResponse';
import AgentsCallHistoryRequestFactory                        from 'message-factory/lib/11058_AgentsCallHistoryRequest';
import AgentsCallHistoryInfoResponseFactory                   from 'message-factory/lib/11059_AgentsCallHistoryInfoResponse';
import AsACDPickableAgentsRequestFactory                      from 'message-factory/lib/11060_AsACDPickableAgentsRequest';
import AsACDPickableAgentsResponseFactory                     from 'message-factory/lib/11061_AsACDPickableAgentsResponse';
import AsACDPickAgentsCallRequestFactory                      from 'message-factory/lib/11062_AsACDPickAgentsCallRequest';
import AsACDPickAgentsCallResponseFactory                     from 'message-factory/lib/11063_AsACDPickAgentsCallResponse';
import AsACDPickedByAgentResponseFactory                      from 'message-factory/lib/11064_AsACDPickedByAgentResponse';
import JunkCallTransitoryAfterCallRequestFactory              from 'message-factory/lib/11065_JunkCallTransitoryAfterCallRequest';
import JunkCallPermanentAfterCallRequestFactory               from 'message-factory/lib/11066_JunkCallPermanentAfterCallRequest';
import NewUmsInboundMessageFactory                            from 'message-factory/lib/11067_NewUmsInboundMessage';
import AsACDPickableMailsRequestFactory                       from 'message-factory/lib/11068_AsACDPickableMailsRequest';
import AsACDPickableMailsResponseFactory                      from 'message-factory/lib/11069_AsACDPickableMailsResponse';
import AsPersonalDataDelReqMsgFactory                         from 'message-factory/lib/11070_AsPersonalDataDelReqMsg';
import TextModuleRequest                                      from 'message-factory/lib/11077_TextModuleRequest';
import TextModuleResponse                                     from 'message-factory/lib/11078_TextModuleResponse';
import RestServiceRequestFactory                              from 'message-factory/lib/11083_RestServiceRequest';
import RestServiceResponseFactory                             from 'message-factory/lib/11084_RestServiceResponse';
import AsEmsCallHistoryReqMsgFactory                          from 'message-factory/lib/11085_AsEmsCallHistoryReqMsg';
import AsNewCallPersonalDataMsg                               from 'message-factory/lib/11088_AsNewCallPersonalDataMsg';
import AsSessionContinueReqMsgFactory                         from 'message-factory/lib/13007_AsSessionContinueReqMsg';
import AsSessionContinueRespMsgFactory                        from 'message-factory/lib/13008_AsSessionContinueRespMsg';
import MakeOutDialErrorReponse                                from 'message-factory/lib/190000_MakeOutDialErrorReponse';
import AcdTransitWarmTakeoverErrorResponse                    from 'message-factory/lib/190002_AcdTransitWarmTakeoverErrorResponse';
import MuteStartRequestFactory                                from 'message-factory/lib/190003_MuteStartRequest';
import MuteStopRequestFactory                                 from 'message-factory/lib/190005_MuteStopRequest';
import MuteStateResponseFactory                               from 'message-factory/lib/190006_MuteStateResponse';
import AcdClientMonitorStartMsgFactory                        from 'message-factory/lib/190007_AcdClientMonitorStartMsg';
import AcdClientMonitorStopMsgFactory                         from 'message-factory/lib/190011_AcdClientMonitorStopMsg';
import AcdTransitAnswerCallFactory                            from 'message-factory/lib/190012_AcdTransitAnswerCall';
import AcdTransitConferenceFactory                            from 'message-factory/lib/190014_AcdTransitConference';
import SideBySideCoachingRequestFactory                       from 'message-factory/lib/190022_SideBySideCoachingRequest';
import ChangeUmsStateResponseFactory                          from 'message-factory/lib/190023_ChangeUmsStateResponse';
import UmsUnsuccessfullMsgFactory                             from 'message-factory/lib/190024_UmsUnsuccessfullMsg';
import AcdTransitPickEmailRequestFactory                      from 'message-factory/lib/190029_AcdTransitPickEmailRequest';
import AcdTransitEmailResponseFactory                         from 'message-factory/lib/190030_AcdTransitPickEmailResponse';
import GroupBlockingRequestFactory                            from 'message-factory/lib/190031_GroupBlockingRequest';
import GroupBlockingResponseFactory                           from 'message-factory/lib/190032_GroupBlockingResponse';
import AcdTransitWaitingQueuePickRequestFactory               from 'message-factory/lib/190036_AcdTransitWaitingQueuePickRequest';
import AcdTransitWaitingQueuePickResponseFactory              from 'message-factory/lib/190037_AcdTransitWaitingQueuePickResponse';
import SideBySideCoachingResponseFactory                      from 'message-factory/lib/190038_SideBySideCoachingResponse';
import RecordingEvaluationStopRequest                         from 'message-factory/lib/190041_AcdRecordingEvaluationStopRequest';
import StartOrStopRecordingRequestFactory                     from 'message-factory/lib/190043_StartOrStopRecordingRequest';
import RecordingStatesResponseFactory                         from 'message-factory/lib/190044_RecordingStatesResponse';
import RecordingEvaluationActiveInfo                          from 'message-factory/lib/190045_AcdRecordingEvaluationActiveInfo';
import ShellExecuteRequestFactory                             from 'message-factory/lib/190050_ShellExecuteRequest';
import AcdTransitExtraRecordingNameInfoRequestFactory         from 'message-factory/lib/190051_AcdTransitExtraRecordingNameInfoRequest';
import BreakReasonChangeResponseFactory                       from 'message-factory/lib/190052_BreakReasonChangeResponse';
import ScreenrecordingStartRequestFactory                     from 'message-factory/lib/190053_ScreenrecordingStartRequest';
import ScreenrecordingStartResponseFactory                    from 'message-factory/lib/190054_ScreenrecordingStartResponse';
import ScreenrecordingResumeFactory                           from 'message-factory/lib/190055_ScreenrecordingResume';
import ScreenrecordingPauseFactory                            from 'message-factory/lib/190056_ScreenrecordingPause';
import TimeDependantCallContactReasonRequestFactory           from 'message-factory/lib/190061_TimeDependantCallContactReasonRequest';
import TimeDependantCallContactReasonResponseFactory          from 'message-factory/lib/190062_TimeDependantCallContactReasonResponse';
import TimeDependantNonVoiceContactReasonRequestFactory       from 'message-factory/lib/190063_TimeDependantNonVoiceContactReasonRequest';
import TimeDependantNonVoiceContactReasonResponseFactory      from 'message-factory/lib/190064_TimeDependantNonVoiceContactReasonResponse';
import AcdCommXpathResultInfoFactory                          from 'message-factory/lib/190066_AcdCommXpathResultInfo';
import UmsPauseOffRequestFactory                              from 'message-factory/lib/190069_UmsPauseOffRequest';
import ScreenrecordingFinishFactory                           from 'message-factory/lib/190071_ScreenrecordingFinish';
import AcdTextInfoForCallOrUmsMsgFactory                      from 'message-factory/lib/190073_AcdTextInfoForCallOrUmsMsg';
import UmsTakeoverRequestFactory                              from 'message-factory/lib/190074_UmsTakeoverRequest';
import ScreenrecordingCancelFactory                           from 'message-factory/lib/190075_ScreenrecordingCancel';
import TextModuleCollectionRequest                            from 'message-factory/lib/190076_TextModuleCollectionRequest';
import AcdTransitTextModuleCollection                         from 'message-factory/lib/190076_AcdTransitTextModuleCollection';
import IsdnBusyInfoMessageFactory                             from 'message-factory/lib/190081_IsdnBusyInfoMessage';
import NewCallRequestFactory                                  from 'message-factory/lib/190085_NewCallRequest';
import SendWorkitemRequestFactory                             from 'message-factory/lib/190090_SendWorkitemRequest';
import LastInboundMessageRequestFactory                       from 'message-factory/lib/190091_LastInboundMessageRequest';
import StartWorkItemRequestFactory                            from 'message-factory/lib/190094_StartWorkItemRequest';
import StopWorkItemRequestFactory                             from 'message-factory/lib/190095_StopWorkItemRequest';
import CancelWorkingItemRequestFactory                        from 'message-factory/lib/190096_CancelWorkingItemRequest';
import NonVoiceContactReasonRequestFactory                    from 'message-factory/lib/190097_NonVoiceContactReasonRequest';
import ClientUmsLastProcessingRequestFactory                  from 'message-factory/lib/190101_ClientUmsLastProcessingRequest';
import AddUserDefinedCallFlowEntryRequestFactory              from 'message-factory/lib/190102_AddUserDefinedCallFlowEntryRequest';
import AddUserDefinedCallFlowEntryResponseFactory             from 'message-factory/lib/190103_AddUserDefinedCallFlowEntryResponse';
import AcdTransitTextModuleCollectionMultipleResponseFactory  from 'message-factory/lib/190107_AcdTransitTextModuleCollectionMultipleResponse';
import FollowUpRequestFactory                                 from 'message-factory/lib/190108_FollowUpRequest';
import FollowUpResponseFactory                                from 'message-factory/lib/190109_FollowUpResponse';
import AcdClientCommUmsSetSleepingResubmissionRequestFactory  from 'message-factory/lib/190113_AcdClientCommUmsSetSleepingResubmissionRequest';
import AcdClientCommUmsSetSleepingResubmissionResponseFactory from 'message-factory/lib/190114_AcdClientCommUmsSetSleepingResubmissionResponse';
import AcdClientCommMultiMsgStoreXmlRequestFactory            from 'message-factory/lib/190115_AcdClientCommMultiMsgStoreXmlRequest';
import AcdClientCommMultiMsgGetXmlRequest                     from 'message-factory/lib/190117_AcdClientCommMultiMsgGetXmlRequest';
import AcdClientCommMultiMsgGetXmlResponse                    from 'message-factory/lib/190118_AcdClientCommMultiMsgGetXmlResponse';
import AcdTransitActionSwitchWorkspaceFactory                 from 'message-factory/lib/190119_AcdTransitActionSwitchWorkspace';
import C2CConnectAgentRequestFactory                          from 'message-factory/lib/200001_C2CConnectAgentRequest';
import C2CConnectAgentResponseFactory                         from 'message-factory/lib/200001_C2CConnectAgentResponse';
import C2CAcceptSupervisorRequestFactory                      from 'message-factory/lib/200002_C2CAcceptSupervisorRequest';
import C2CAcceptSupervisorResponseFactory                     from 'message-factory/lib/200002_C2CAcceptSupervisorResponse';
import C2CDisconnectAgentRequestFactory                       from 'message-factory/lib/200003_C2CDisconnectAgentRequest';
import C2CDisconnectAgentResponseFactory                      from 'message-factory/lib/200003_C2CDisconnectAgentResponse';
import C2CDisconnectSupervisorRequestFactory                  from 'message-factory/lib/200004_C2CDisconnectSupervisorRequest';
import C2CDisconnectSupervisorResponseFactory                 from 'message-factory/lib/200004_C2CDisconnectSupervisorResponse';
import C2CSyncAcdExtAgentStateFactory                         from 'message-factory/lib/200010_C2CSyncAcdExtAgentState';
import C2CSyncCallFactory                                     from 'message-factory/lib/200012_C2CSyncCall';
import C2CMonitoringStartFactory                              from 'message-factory/lib/210050_C2CMonitoringStart';
import CoachChatMessageRequest                                from 'message-factory/lib/250014_CoachChatMessageRequest';
import CoachChatMessageResponse                               from 'message-factory/lib/250014_CoachChatMessageResponse';
import AsNewCallInfo                                          from 'message-factory/lib/10007_AsNewCallInfo';
import CallAgentNowRequestFactory                             from 'message-factory/lib/10040_CallAgentNowRequestFactory';
import AsDialNowReq                                           from 'message-factory/lib/10008_AsDialNowReq';
import AsHangUpReq                                            from 'message-factory/lib/10010_AsHangUpReq';
import AsNewCallReq                                           from 'message-factory/lib/10021_AsNewCallReq';
import AgentsCampaignsRequest                                 from 'message-factory/lib/10074_AgentsCampaignsRequest';
import AgentsCampaignsResponse                                from 'message-factory/lib/10075_AgentsCampaignsResponse';
import CampaignBlockingRequest                                from 'message-factory/lib/10076_CampaignBlockingRequest';
import AsDialerCustomerConfigRequestFactory                   from 'message-factory/lib/10060_AsDialerCustomerConfigReq';
import AsDialerCustomerConfigRespFactory                      from 'message-factory/lib/10061_AsDialerCustomerConfigResp';
import AsWrapUpReqFactory                                     from 'message-factory/lib/10011_AsWrapUpReq';
import AsDialerAgentConfigReqFactory                          from 'message-factory/lib/10111_AsDialerAgentConfigReq';
import AsDialerAgentConfigRespFactory                         from 'message-factory/lib/10112_AsDialerAgentConfigResp';
import AsACDWrapUpForEditNewCallFactory                       from 'message-factory/lib/10105_AsACDWrapUpForEditNewCallMsg';
import AsDialerEditTogo4BlendingRespMsgFactory                from 'message-factory/lib/10107_AsDialerEditTogo4BlendingRespMsg';
import AsACDEditTogo4BlendingReqMsgFactory                    from 'message-factory/lib/10103_AsACDEditTogo4BlendingReqMsg';
import AsSearchToGoEntryReqFactory                            from 'message-factory/lib/10101_AsSearchToGoEntryReqMsg';
import AsSearchToGoEntryRespFactory                           from 'message-factory/lib/10102_AsSearchToGoEntryRespMsg';
import AsReleaseForEditNewCallFactory                         from 'message-factory/lib/10106_AsReleaseForEditNewCall';
import AsWrapUpRespMsgFactory                                 from 'message-factory/lib/10012_AsWrapUpRespMsg';
import AsPseudoWrapUpReq                                      from 'message-factory/lib/10019_AsPseudoWrapUpReq';
import AcdCreateNewOutboundMessageReqFactory                  from 'message-factory/lib/190092_AcdCreateNewOutboundMessageReq';
import AcdCreateNewOutboundMessageRespFactory                 from 'message-factory/lib/190093_AcdCreateNewOutboundMessageResp';
import AsUmsTemplateReqFactory                                from 'message-factory/lib/10026_AsUmsTemplateReq';
import AsUmsTemplateRespFactory                               from 'message-factory/lib/10027_AsUmsTemplateResp';
import AsUmsMessageReqFactory                                 from 'message-factory/lib/10028_AsUmsMessageReq';
import TextModuleCollectionReq                                from 'message-factory/lib/10129_TextModuleCollectionRequest';
import AsAgentsAppointmentsReqMsgFactory                      from 'message-factory/lib/10077_AsAgentsAppointmentsReqMsg';
import AsAgentsAppointmentsRespMsg                            from 'message-factory/lib/10078_AsAgentsAppointmentsRespMsg';
import AsDialerMonitorReqMsg                                  from 'message-factory/lib/10127_AsDialerMonitorMsg';
import AsDialerMonitorAppointmentPreviewRespMsg               from 'message-factory/lib/10128_AsDialerMonitorAppointmentPreviewMsg';
import AsAgentResultStatisticReqMsg                           from 'message-factory/lib/10120_AsAgentResultStatisticReqMsg';
import AsAgentResultStatisticRespMsg                          from 'message-factory/lib/10121_AsAgentResultStatisticRespMsg';
import AsAgentHistoryMsg                                      from 'message-factory/lib/10015_AsHistoryMsg';
import AsCallFlowUpdateReqMsgFactory                          from 'message-factory/lib/11049_AsCallFlowUpdateReqMsg';
import AcdClientCommCallerHangUpRespFactory                   from 'message-factory/lib/190028_AcdClientCommCallerHangUpResp';
import AcdTransitStartBlending                                from 'message-factory/lib/190035_AcdTransitStartBlending';
import AsDialNotReqMsgFactory                                 from 'message-factory/lib/10016_AsDialNotReqMsg';
import AcdInterimReplyRequestFactory                          from 'message-factory/lib/190110_AcdInterimReplyRequest';
import AcdInterimReplyResponseFactory                         from 'message-factory/lib/190111_AcdInterimReplyResponse';

import MultichannelSdk from '@multichannel/sdk';
import Compareable from '@multichannel/sdk/src/Comparable';

export const ResponseFactoryMap = {
  [PingFactory.type()]                                           : new PingFactory(),
  [GeneralResponseFactory.type()]                                : new GeneralResponseFactory(),
  [LoginResponseFactory.type()]                                  : new LoginResponseFactory(),
  [InitialisationResponseFactory.type()]                         : new InitialisationResponseFactory(),
  [ChangeStateFactory.type()]                                    : new ChangeStateFactory(),
  [ChangeExtendedStateFactory.type()]                            : new ChangeExtendedStateFactory(),
  [CustomerConfigResponseFactory.type()]                         : new CustomerConfigResponseFactory(),
  [NewCallInfoFactory.type()]                                    : new NewCallInfoFactory(),
  [HistoryInfoFactory.type()]                                    : new HistoryInfoFactory(),
  [GroupConfigDataResponseFactory.type()]                        : new GroupConfigDataResponseFactory(),
  [NewOutCallInfoResponseFactory.type()]                         : new NewOutCallInfoResponseFactory(),
  [GroupMonitorDataResponseFactory.type()]                       : new GroupMonitorDataResponseFactory(),
  [NewUmsInboundMessageFactory.type()]                           : new NewUmsInboundMessageFactory(),
  [ChangeUmsStateResponseFactory.type()]                         : new ChangeUmsStateResponseFactory(),
  [RestServiceResponseFactory.type()]                            : new RestServiceResponseFactory(),
  [AgentsCallHistoryInfoResponseFactory.type()]                  : new AgentsCallHistoryInfoResponseFactory(),
  [AcdCommXpathResultInfoFactory.type()]                         : new AcdCommXpathResultInfoFactory(),
  [AcdTextInfoForCallOrUmsMsgFactory.type()]                     : new AcdTextInfoForCallOrUmsMsgFactory(),
  [AgentsBlockedGroupResponseFactory.type()]                     : new AgentsBlockedGroupResponseFactory(),
  [GroupBlockingResponseFactory.type()]                          : new GroupBlockingResponseFactory(),
  [AgentMonitorInitDataFactory.type()]                           : new AgentMonitorInitDataFactory(),
  [AgentMonitorUpdateDataFactory.type()]                         : new AgentMonitorUpdateDataFactory(),
  [AgentMonitorConfigurationResponseFactory.type()]              : new AgentMonitorConfigurationResponseFactory(),
  [AgentConfigResponseFactory.type()]                            : new AgentConfigResponseFactory(),
  [FollowUpResponseFactory.type()]                               : new FollowUpResponseFactory(),
  [RecordingStatesResponseFactory.type()]                        : new RecordingStatesResponseFactory(),
  [IsdnBusyInfoMessageFactory.type()]                            : new IsdnBusyInfoMessageFactory(),
  [AddUserDefinedCallFlowEntryResponseFactory.type()]            : new AddUserDefinedCallFlowEntryResponseFactory(),
  [MuteStateResponseFactory.type()]                              : new MuteStateResponseFactory(),
  [BreakReasonChangeResponseFactory.type()]                      : new BreakReasonChangeResponseFactory(),
  [TapiCallsCountInfoFactory.type()]                             : new TapiCallsCountInfoFactory(),
  [AcdTransitActionSwitchWorkspaceFactory.type()]                : new AcdTransitActionSwitchWorkspaceFactory(),
  [UserStatisticResponseFactory.type()]                          : new UserStatisticResponseFactory(),
  [AcdClientCommUmsSetSleepingResubmissionResponseFactory.type()]: new AcdClientCommUmsSetSleepingResubmissionResponseFactory(),
  [AsPersonalDataGetRespMsgFactory.type()]                       : new AsPersonalDataGetRespMsgFactory(),
  [AsACDPickableAgentsResponseFactory.type()]                    : new AsACDPickableAgentsResponseFactory(),
  [AsACDPickAgentsCallResponseFactory.type()]                    : new AsACDPickAgentsCallResponseFactory(),
  [AsACDPickedByAgentResponseFactory.type()]                     : new AsACDPickedByAgentResponseFactory(),
  [AcdTransitWaitingQueuePickResponseFactory.type()]             : new AcdTransitWaitingQueuePickResponseFactory(),
  [AsACDPickableMailsResponseFactory.type()]                     : new AsACDPickableMailsResponseFactory(),
  [AsDialerPauseOnResponseFactory.type()]                        : new AsDialerPauseOnResponseFactory(),
  [CoachChatMessageResponse.type()]                              : new CoachChatMessageResponse(),
  [AcdTransitEmailResponseFactory.type()]                        : new AcdTransitEmailResponseFactory(),
  [AsPersonalDataAddRespMsgFactory.type()]                       : new AsPersonalDataAddRespMsgFactory(),
  [SideBySideCoachingResponseFactory.type()]                     : new SideBySideCoachingResponseFactory(),
  [TextModuleResponse.type()]                                    : new TextModuleResponse(),
  [TextModuleCollectionResponse.type()]                          : new TextModuleCollectionResponse(),
  [AcdTransitTextModuleCollection.type()]                        : new AcdTransitTextModuleCollection(),
  [AsNewCallPersonalDataMsg.type()]                              : new AsNewCallPersonalDataMsg(),
  [AsCallFlowGetRespMsgFactory.type()]                           : new AsCallFlowGetRespMsgFactory(),
  [AsConnectedClientsRespMsgFactory.type()]                      : new AsConnectedClientsRespMsgFactory(),
  [C2CConnectAgentResponseFactory.type()]                        : new C2CConnectAgentResponseFactory(),
  [C2CAcceptSupervisorResponseFactory.type()]                    : new C2CAcceptSupervisorResponseFactory(),
  [C2CDisconnectAgentResponseFactory.type()]                     : new C2CDisconnectAgentResponseFactory(),
  [C2CDisconnectSupervisorResponseFactory.type()]                : new C2CDisconnectSupervisorResponseFactory(),
  [AsSessionContinueRespMsgFactory.type()]                       : new AsSessionContinueRespMsgFactory(),
  [AgentsCampaignsResponse.type()]                               : new AgentsCampaignsResponse(),
  [AsNewCallInfo.type()]                                         : new AsNewCallInfo(),
  [AsDialerCustomerConfigRespFactory.type()]                     : new AsDialerCustomerConfigRespFactory(),
  [TimeDependantCallContactReasonResponseFactory.type()]         : new TimeDependantCallContactReasonResponseFactory(),
  [TimeDependantNonVoiceContactReasonResponseFactory.type()]     : new TimeDependantNonVoiceContactReasonResponseFactory(),
  [AsDialerAgentConfigRespFactory.type()]                        : new AsDialerAgentConfigRespFactory(),
  [CampaignConfigRespMsgFactory.type()]                          : new CampaignConfigRespMsgFactory(),
  [AsDialerEditTogo4BlendingRespMsgFactory.type()]               : new AsDialerEditTogo4BlendingRespMsgFactory(),
  [DialerAgentCallHistoryResponseFactory.type()]                 : new DialerAgentCallHistoryResponseFactory(),
  [AsSearchToGoEntryRespFactory.type()]                          : new AsSearchToGoEntryRespFactory(),
  [ShellExecuteRequestFactory.type()]                            : new ShellExecuteRequestFactory(),
  [AsVoiceRecStatusRespFactory.type()]                           : new AsVoiceRecStatusRespFactory(),
  [AsWrapUpRespMsgFactory.type()]                                : new AsWrapUpRespMsgFactory(),
  [AcdTransitTextModuleCollectionMultipleResponseFactory.type()] : new AcdTransitTextModuleCollectionMultipleResponseFactory(),
  [AsDialerTakeOverCallResponseFactory.type()]                   : new AsDialerTakeOverCallResponseFactory(),
  [AcdCreateNewOutboundMessageRespFactory.type()]                : new AcdCreateNewOutboundMessageRespFactory(),
  [AsUmsTemplateRespFactory.type()]                              : new AsUmsTemplateRespFactory(),
  [AsUmsGetAllOrdersRespMsg.type()]                              : new AsUmsGetAllOrdersRespMsg(),
  [MakeOutDialErrorReponse.type()]                               : new MakeOutDialErrorReponse(),
  [AcdTransitWarmTakeoverErrorResponse.type()]                   : new AcdTransitWarmTakeoverErrorResponse(),
  [AsAgentsAppointmentsRespMsg.type()]                           : new AsAgentsAppointmentsRespMsg(),
  [RecordingEvaluationActiveInfo.type()]                         : new RecordingEvaluationActiveInfo(),
  [AsDialerMonitorAppointmentPreviewRespMsg.type()]              : new AsDialerMonitorAppointmentPreviewRespMsg(),
  [AsNextCallsPreviewMsg.type()]                                 : new AsNextCallsPreviewMsg(),
  [AsAgentResultStatisticRespMsg.type()]                         : new AsAgentResultStatisticRespMsg(),
  [AcdClientCommMultiMsgGetXmlResponse.type()]                   : new AcdClientCommMultiMsgGetXmlResponse(),
  [AsAgentHistoryMsg.type()]                                     : new AsAgentHistoryMsg(),
  [AcdClientCommCallerHangUpRespFactory.type()]                  : new AcdClientCommCallerHangUpRespFactory(),
  [ScreenrecordingStartRequestFactory.type()]                    : new ScreenrecordingStartRequestFactory(), //Client will receive this request
  [ScreenrecordingResumeFactory.type()]                          : new ScreenrecordingResumeFactory(),
  [ScreenrecordingPauseFactory.type()]                           : new ScreenrecordingPauseFactory(),
  [ScreenrecordingFinishFactory.type()]                          : new ScreenrecordingFinishFactory(),
  [ScreenrecordingCancelFactory.type()]                          : new ScreenrecordingCancelFactory(),
  [AsSmSwitchToServiceMsgFactory.type()]                         : new AsSmSwitchToServiceMsgFactory(),
  [AcdInterimReplyResponseFactory.type()]                        : new AcdInterimReplyResponseFactory()
};

export const RequestFactoryMap = {
  [AsDialerTakeOverCallReconnectRequestFactory.type()]           : new AsDialerTakeOverCallReconnectRequestFactory(),
  [AcdClientCommUmsSetSleepingResubmissionRequestFactory.type()]: new AcdClientCommUmsSetSleepingResubmissionRequestFactory(),
  [AcdClientMonitorStartMsgFactory.type()]                       : new AcdClientMonitorStartMsgFactory(),
  [AcdClientMonitorStopMsgFactory.type()]                        : new AcdClientMonitorStopMsgFactory(),
  [AcdTransitAnswerCallFactory.type()]                           : new AcdTransitAnswerCallFactory(),
  [AcdTransitConferenceFactory.type()]                           : new AcdTransitConferenceFactory(),
  [UserStatisticRequestFactory.type()]                           : new UserStatisticRequestFactory(),
  [AcdTransitExtraRecordingNameInfoRequestFactory.type()]        : new AcdTransitExtraRecordingNameInfoRequestFactory(),
  [AcdTransitPickEmailRequestFactory.type()]                     : new AcdTransitPickEmailRequestFactory(),
  [AcdTransitWaitingQueuePickRequestFactory.type()]              : new AcdTransitWaitingQueuePickRequestFactory(),
  [AddUserDefinedCallFlowEntryRequestFactory.type()]             : new AddUserDefinedCallFlowEntryRequestFactory(),
  [AgentConfigRequestFactory.type()]                             : new AgentConfigRequestFactory(),
  [AgentMonitorConfigurationRequestFactory.type()]               : new AgentMonitorConfigurationRequestFactory(),
  [AgentMonitorStartFactory.type()]                              : new AgentMonitorStartFactory(),
  [AgentsBlockedGroupRequestFactory.type()]                      : new AgentsBlockedGroupRequestFactory(),
  [AgentsCallHistoryRequestFactory.type()]                       : new AgentsCallHistoryRequestFactory(),
  [AsACDPickableAgentsRequestFactory.type()]                     : new AsACDPickableAgentsRequestFactory(),
  [AsACDPickableMailsRequestFactory.type()]                      : new AsACDPickableMailsRequestFactory(),
  [AsACDPickAgentsCallRequestFactory.type()]                     : new AsACDPickAgentsCallRequestFactory(),
  [AsCallFlowGetReqMsgFactory.type()]                            : new AsCallFlowGetReqMsgFactory(),
  [AsConnectedClientsReqMsgFactory.type()]                       : new AsConnectedClientsReqMsgFactory(),
  [AsEmsCallHistoryReqMsgFactory.type()]                         : new AsEmsCallHistoryReqMsgFactory(),
  [AsPersonalDataAddReqMsgFactory.type()]                        : new AsPersonalDataAddReqMsgFactory(),
  [AsPersonalDataDelReqMsgFactory.type()]                        : new AsPersonalDataDelReqMsgFactory(),
  [AsPersonalDataEditReqMsgFactory.type()]                       : new AsPersonalDataEditReqMsgFactory(),
  [AsPersonalDataGetReqMsgFactory.type()]                        : new AsPersonalDataGetReqMsgFactory(),
  [AsSessionContinueReqMsgFactory.type()]                        : new AsSessionContinueReqMsgFactory(),
  [C2CAcceptSupervisorRequestFactory.type()]                     : new C2CAcceptSupervisorRequestFactory(),
  [C2CConnectAgentRequestFactory.type()]                         : new C2CConnectAgentRequestFactory(),
  [C2CDisconnectAgentRequestFactory.type()]                      : new C2CDisconnectAgentRequestFactory(),
  [C2CDisconnectSupervisorRequestFactory.type()]                 : new C2CDisconnectSupervisorRequestFactory(),
  [C2CMonitoringStartFactory.type()]                             : new C2CMonitoringStartFactory(),
  [C2CSyncAcdExtAgentStateFactory.type()]                        : new C2CSyncAcdExtAgentStateFactory(),
  [C2CSyncCallFactory.type()]                                    : new C2CSyncCallFactory(),
  [CallResultRequestFactory.type()]                              : new CallResultRequestFactory(),
  [CancelWorkingItemRequestFactory.type()]                       : new CancelWorkingItemRequestFactory(),
  [ClientExitRequestFactory.type()]                              : new ClientExitRequestFactory(),
  [ClientUmsLastProcessingRequestFactory.type()]                 : new ClientUmsLastProcessingRequestFactory(),
  [CustomerConfigRequestFactory.type()]                          : new CustomerConfigRequestFactory(),
  [AsDialerPauseOnRequestFactory.type()]                         : new AsDialerPauseOnRequestFactory(),
  [EndCallRequestFactory.type()]                                 : new EndCallRequestFactory(),
  [FollowUpRequestFactory.type()]                                : new FollowUpRequestFactory(),
  [GroupBlockingRequestFactory.type()]                           : new GroupBlockingRequestFactory(),
  [GroupConfigDataRequestFactory.type()]                         : new GroupConfigDataRequestFactory(),
  [GroupMonitorStartFactory.type()]                              : new GroupMonitorStartFactory(),
  [JunkCallPermanentAfterCallRequestFactory.type()]              : new JunkCallPermanentAfterCallRequestFactory(),
  [JunkCallTransitoryAfterCallRequestFactory.type()]             : new JunkCallTransitoryAfterCallRequestFactory(),
  [LastInboundMessageRequestFactory.type()]                      : new LastInboundMessageRequestFactory(),
  [LoginRequestFactory.type()]                                   : new LoginRequestFactory(),
  [LogoutRequestFactory.type()]                                  : new LogoutRequestFactory(),
  [MakeOutCallRequestFactory.type()]                             : new MakeOutCallRequestFactory(),
  [MuteStartRequestFactory.type()]                               : new MuteStartRequestFactory(),
  [MuteStopRequestFactory.type()]                                : new MuteStopRequestFactory(),
  [NewCallRequestFactory.type()]                                 : new NewCallRequestFactory(),
  [NonVoiceContactReasonRequestFactory.type()]                   : new NonVoiceContactReasonRequestFactory(),
  [NumberCompletionRequestFactory.type()]                        : new NumberCompletionRequestFactory(),
  [OnHoldRequestFactory.type()]                                  : new OnHoldRequestFactory(),
  [PauseRequestFactory.type()]                                   : new PauseRequestFactory(),
  [PongFactory.type()]                                           : new PongFactory(),
  [QueryRequestFactory.type()]                                   : new QueryRequestFactory(),
  [ReadyRequestFactory.type()]                                   : new ReadyRequestFactory(),
  [ReconnectRequestFactory.type()]                               : new ReconnectRequestFactory(),
  [RestServiceRequestFactory.type()]                             : new RestServiceRequestFactory(),
  [SendWorkitemRequestFactory.type()]                            : new SendWorkitemRequestFactory(),
  [ShellExecuteRequestFactory.type()]                            : new ShellExecuteRequestFactory(),
  [SideBySideCoachingRequestFactory.type()]                      : new SideBySideCoachingRequestFactory(),
  [StartOrStopRecordingRequestFactory.type()]                    : new StartOrStopRecordingRequestFactory(),
  [StartWorkItemRequestFactory.type()]                           : new StartWorkItemRequestFactory(),
  [StatusRequestFactory.type()]                                  : new StatusRequestFactory(),
  [StopWorkItemRequestFactory.type()]                            : new StopWorkItemRequestFactory(),
  [TextModuleCollectionRequest.type()]                           : new TextModuleCollectionRequest(),
  [TextModuleRequest.type()]                                     : new TextModuleRequest(),
  [TimeDependantCallContactReasonRequestFactory.type()]          : new TimeDependantCallContactReasonRequestFactory(),
  [TimeDependantNonVoiceContactReasonRequestFactory.type()]      : new TimeDependantNonVoiceContactReasonRequestFactory(),
  [TransferBlindRequestFactory.type()]                           : new TransferBlindRequestFactory(),
  [TransferRequestFactory.type()]                                : new TransferRequestFactory(),
  [AsHangUpAgentMsgFactory.type()]                               : new AsHangUpAgentMsgFactory(),
  [UmsGetAllOrdersRequestFactory.type()]                         : new UmsGetAllOrdersRequestFactory(),
  [UmsPauseOffRequestFactory.type()]                             : new UmsPauseOffRequestFactory(),
  [UmsTakeoverRequestFactory.type()]                             : new UmsTakeoverRequestFactory(),
  [UmsUnsuccessfullMsgFactory.type()]                            : new UmsUnsuccessfullMsgFactory(),
  [WrapUpRequestFactory.type()]                                  : new WrapUpRequestFactory(),
  [CallAgentNowRequestFactory.type()]                            : new CallAgentNowRequestFactory(),
  [AsDialNowReq.type()]                                          : new AsDialNowReq(),
  [AsHangUpReq.type()]                                           : new AsHangUpReq(),
  [AsNewCallReq.type()]                                          : new AsNewCallReq(),
  [AgentsCampaignsRequest.type()]                                : new AgentsCampaignsRequest(),
  [CampaignBlockingRequest.type()]                               : new CampaignBlockingRequest(),
  [AsDialerCustomerConfigRequestFactory.type()]                  : new AsDialerCustomerConfigRequestFactory(),
  [AsWrapUpReqFactory.type()]                                    : new AsWrapUpReqFactory(),
  [AsNextCallsPreviewStartFactory.type()]                        : new AsNextCallsPreviewStartFactory(),
  [AsNextCallsPreviewStopFactory.type()]                         : new AsNextCallsPreviewStopFactory(),
  [AsDialerAgentConfigReqFactory.type()]                         : new AsDialerAgentConfigReqFactory(),
  [CampaignConfigReqMsgFactory.type()]                           : new CampaignConfigReqMsgFactory(),
  [CoachChatMessageRequest.type()]                               : new CoachChatMessageRequest(),
  [AsACDWrapUpForEditNewCallFactory.type()]                      : new AsACDWrapUpForEditNewCallFactory(),
  [AsACDEditTogo4BlendingReqMsgFactory.type()]                   : new AsACDEditTogo4BlendingReqMsgFactory(),
  [DialerAgentCallHistoryRequestFactory.type()]                  : new DialerAgentCallHistoryRequestFactory(),
  [AsSearchToGoEntryReqFactory.type()]                           : new AsSearchToGoEntryReqFactory(),
  [AsReleaseForEditNewCallFactory.type()]                        : new AsReleaseForEditNewCallFactory(),
  [AsVoiceRecStopReqFactory.type()]                              : new AsVoiceRecStopReqFactory(),
  [AsVoiceRecStartReqFactory.type()]                             : new AsVoiceRecStartReqFactory(),
  [AsPseudoWrapUpReq.type()]                                     : new AsPseudoWrapUpReq(),
  [AsDialerTakeOverCallRequestFactory.type()]                    : new AsDialerTakeOverCallRequestFactory(),
  [AsDialerTakeOverCommitRequestFactory.type()]                  : new AsDialerTakeOverCommitRequestFactory(),
  [AcdCreateNewOutboundMessageReqFactory.type()]                 : new AcdCreateNewOutboundMessageReqFactory(),
  [AsUmsTemplateReqFactory.type()]                               : new AsUmsTemplateReqFactory(),
  [AsUmsMessageReqFactory.type()]                                : new AsUmsMessageReqFactory(),
  [TextModuleCollectionReq.type()]                               : new TextModuleCollectionReq(),
  [AsAgentsAppointmentsReqMsgFactory.type()]                     : new AsAgentsAppointmentsReqMsgFactory(),
  [RecordingEvaluationStopRequest.type()]                        : new RecordingEvaluationStopRequest(),
  [AsDialerMonitorReqMsg.type()]                                 : new AsDialerMonitorReqMsg(),
  [AsAgentResultStatisticReqMsg.type()]                          : new AsAgentResultStatisticReqMsg(),
  [AcdClientCommMultiMsgStoreXmlRequestFactory.type()]           : new AcdClientCommMultiMsgStoreXmlRequestFactory(),
  [AcdClientCommMultiMsgGetXmlRequest.type()]                    : new AcdClientCommMultiMsgGetXmlRequest(),
  [AsCallFlowUpdateReqMsgFactory.type()]                         : new AsCallFlowUpdateReqMsgFactory(),
  [ScreenrecordingStartResponseFactory.type()]                   : new ScreenrecordingStartResponseFactory(), //Client will send this response
  [AcdTransitStartBlending.type()]                               : new AcdTransitStartBlending(),
  [AsDialNotReqMsgFactory.type()]                                : new AsDialNotReqMsgFactory(),
  [AcdInterimReplyRequestFactory.type()]                         : new AcdInterimReplyRequestFactory()
};

/**
 *
 * Handles creation of MessageFactories.
 *
 * MessageFactories are used to create message objects according to the protobuf specifications.
 *
 */
export class MessageFactoryProducer extends Compareable {
  _api: MultichannelSdk;
  _factories;
  _logger;

  /**
   * Instantiates a new MessageFactoryProducer;
   *
   * @param {VierComApi} api - Reference to the api this belongs to
   */
  constructor(api: MultichannelSdk) {
    super();
    this._api = api;
    this._logger = api.debug('api.message.messageFactoryProducer');

    this._factories = {};
  }

  /**
   * Creates and returns a MessageFactory for the given name.
   * If no factory can be found null is returned
   *
   * @param {string} name - The name by which to look up the MessageFactory.
   *
   * @throws {TypeError} The returned MessageFactory must derive from AbstractMessageFactory.
   *
   * @returns {?AbstractMessageFactory} - the message factory for the given name, or null if there is not factory defined for that name
   */
  getRequestFactory(name) {
    this._logger.traceCall('getFactory', name);
    if (this._factories[name]) {
      return this._factories[name];
    }

    let factory;
    factory = name;

    factory = RequestFactoryMap[factory];

    if (factory) {
      if (!(Object.getPrototypeOf(factory) instanceof AbstractMessageFactory)) {
        throw new TypeError(
          `MessageFactory for ${name} does not derive from AbstractMessageFactory`,
        );
      }

      this._factories[name] = factory;
      return factory;
    }
  }
}
