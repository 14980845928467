import React from 'react';
import PropTypes from 'prop-types';
import { exactProp } from '@material-ui/utils';
import { CssBaseline, makeStyles } from '@material-ui/core';
import DinLight from './fonts/DIN-light.woff';
import DinRegular from './fonts/DIN-regular.woff';
import DinMedium from './fonts/DIN-medium.woff';
import DinBold from './fonts/DIN-bold.woff';
import defaultTheme from './defaultTheme';
import MaterialIcons4com from './fonts/material-icons-4com.woff';

const dinLight = {
  fontFamily : 'DIN',
  fontStyle  : 'normal',
  fontDisplay: 'swap',
  fontWeight : 300,
  src        : `
    url(${DinLight}) format('woff2')
  `,
};
const din = {
  fontFamily : 'DIN',
  fontStyle  : 'normal',
  fontDisplay: 'swap',
  fontWeight : 400,
  src        : `
    url(${DinRegular}) format('woff2')
  `,
};
const dinMedium = {
  fontFamily : 'DIN',
  fontStyle  : 'normal',
  fontDisplay: 'swap',
  fontWeight : 500,
  src        : `
    url(${DinMedium}) format('woff2')
  `,
};
const dinBold = {
  fontFamily : 'DIN',
  fontStyle  : 'normal',
  fontDisplay: 'swap',
  fontWeight : 700,
  src        : `
    url(${DinBold}) format('woff2')
  `,
};

const vierComIcons = {
  fontFamily : 'MaterialIcons4Com',
  fontStyle  : 'normal',
  fontDisplay: 'swap',
  fontWeight : 400,
  src        : `
    url(${MaterialIcons4com}) format('woff2')
  `,
};

const useStyles = makeStyles(
  // eslint-disable-next-line no-unused-vars
  theme => ({
    '@global': {
      '@font-face': [dinLight, dinBold, dinMedium, din, vierComIcons],
      '*'         : {
        margin        : 0,
        border        : 0,
        padding       : 0,
        boxSizing     : 'border-box',
        scrollbarWidth: 'thin',
      },
      html: {
        fontFamily                : 'sans-serif',
        lineHeight                : 1.15,
        '-ms-text-size-adjust'    : '100%',
        '-webkit-text-size-adjust': '100%',
      },
      body: {
        margin: 0,
      },
      'article, aside, footer, header, nav, section': {
        display: 'block',
      },
      '.material-icons-4com': {
        fontFamily         : 'MaterialIcons4Com',
        fontWeight         : 'normal',
        fontStyle          : 'normal',
        fontSize           : '16px',
        lineHeight         : 1,
        letterSpacing      : 'normal',
        textTransform      : 'none',
        display            : 'inline-block',
        whiteSpace         : 'nowrap',
        wordWrap           : 'normal',
        direction          : 'ltr',
        fontFeatureSettings: 'liga',
        cursor             : 'inherit',
        userSelect         : 'none',
      },
      '.material-icons-4com.dp16': {
        fontSize: '16px',
        width   : '16px',
        height  : '16px',
      },
      '.material-icons-4com.dp24': {
        fontSize: '24px',
        width   : '24px',
        height  : '24px',
      },
      '.material-icons-4com.dp36': {
        fontSize: '36px',
        width   : '36px',
        height  : '36px',
      },
      '.material-icons-4com.dp48': {
        fontSize: '48px',
        width   : '48px',
        height  : '48px',
      },
      'figcaption, figure, main': {
        display: 'block',
      },
      figure: {
        margin: '1em 40px',
      },
      hr: {
        boxSizing: 'content-box',
        height   : 0,
        overflow : 'visible',
      },
      pre: {
        fontFamily: 'monospace, monospace',
        fontSize  : '1em',
      },
      a: {
        backgroundColor               : 'transparent',
        '-webkit-text-decoration-skip': 'objects',
      },
      'a:active, a:hover': {
        outlineWidth: 0,
      },
      'abbr[title]': {
        borderBottom  : 'none',
        textDecoration: 'underline dotted',
      },
      'b, strong': {
        fontWeight: 'bolder',
      },
      'code, kbd, samp': {
        fontFamily: 'monospace, monospace',
        fontSize  : '1em',
      },
      dfn: {
        fontStyle: 'italic',
      },
      mark: {
        backgroundColor: '#ff0',
        color          : '#000',
      },
      small: {
        fontSize: '80%',
      },
      'sub, sup': {
        fontSize     : '75%',
        lineHeight   : '0',
        position     : 'relative',
        verticalAlign: 'baseline',
      },
      sub: {
        bottom: '-0.25em',
        top   : '-0.5em',
      },
      'audio, video': {
        display: 'inline-block',
      },
      'audio:not([controls])': {
        display: 'none',
        height : 0,
      },
      img: {
        borderStyle: 'none',
      },
      'svg:not(:root)': {
        overflow: 'hidden',
      },
      'button, input, optgroup, select, textarea': {
        fontFamily: 'inherit',
        fontSize  : '100%',
        lineHeight: '1.15',
        margin    : 0,
      },
      'button, input': {
        overflow: 'visible',
      },
      'input:disabled': {
        backgroundColor: '#e0e0e0',
        borderColor    : '#bdbdbd',
        color          : '#616161',
        cursor         : 'not-allowed',
      },
      'button, select': {
        textTransform: 'none',
      },
      'button, html [type="button"], [type="reset"], [type="submit"]': {
        '-webkit-appearance': 'button',
      },
      'button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner': {
        borderStyle: 'none',
        padding    : 0,
      },
      'button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring': {
        outline: '1px dotted ButtonText',
      },
      fieldset: {
        border : '1px solid #c0c0c0',
        margin : '0 2px',
        padding: '0.35em 0.625em 0.75em',
      },
      legend: {
        boxSizing : 'border-box',
        color     : 'inherit',
        display   : 'table',
        maxWidth  : '100%',
        padding   : '0',
        whiteSpace: 'normal',
      },
      progress: {
        display      : 'inline-block',
        verticalAlign: 'baseline',
      },
      textarea: {
        overflow: 'auto',
      },
      '[type="checkbox"], [type="radio"]': {
        boxSizing: 'border-box',
        padding  : 0,
      },
      '[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button': {
        height: 'auto',
      },
      '[type="search"]': {
        '-webkit-appearance': 'textfield',
        outlineOffset       : '-2px',
      },
      '[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration': {
        '-webkit-appearance': 'none',
      },
      '::-webkit-file-upload-button': {
        '-webkit-appearance': 'button',
        font                : 'inherit',
      },
      'details, menu': {
        display: 'block',
      },
      summary: {
        display: 'list-item',
      },
      canvas: {
        display: 'inline-block',
      },
      template: {
        display: 'none',
      },
      '[hidden]': {
        display: 'none',
      },
      'html, body': {
        margin                                                : '0',
        padding                                               : '0',
        width                                                 : '100%',
        height                                                : '100%',
        overflow                                              : 'hidden',
        lineHeight                                            : '1.15',
        fontSize                                              : '12px',
        letterSpacing                                         : '.2px',
        color                                                 : defaultTheme.palette.text.primary,
        '-ms-text-size-adjust'                                : '100%',
        '-webkit-text-size-adjust'                            : '100%',
        scrollbarBehavior                                     : 'smooth',
        '@media screen and (-webkit-min-device-pixel-ratio:0)': {
          '-webkit-font-smoothing': 'antialiased',
        },
        '@supports (-moz-osx-font-smoothing: auto)': {
          '-moz-osx-font-smoothing': 'auto',
        },
        '& .sb-show-main': {
          overflow: 'auto',
        },
      },
      'body > div'           : { height: '100%' },
      '.drop-shadow-override': {
        boxShadow: defaultTheme.dimensions.shadowDialog,
      },
      'h1, h2, h3, h4, h5, h6': {
        fontWeight: defaultTheme.typography.fontWeightMedium
      },
      'h1': {
        fontSize: defaultTheme.typography.size.xl,
      },
      'h2': {
        fontSize: defaultTheme.typography.size.l
      },
      'h3': {
        fontSize: defaultTheme.typography.size.m
      },
      'h4': {
        fontSize: defaultTheme.typography.size.m
      },
      'h5': {
        fontSize: defaultTheme.typography.size.s
      }
    },
  }),
  { name: 'VierComCssBaseline' },
);

function VierComCssBaseline(props) {
  const { children = null } = props;
  useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      {children}
    </React.Fragment>
  );
}

VierComCssBaseline.propTypes = {
  /**
   * You can wrap a node.
   */
  children: PropTypes.node,
};

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line
  VierComCssBaseline['propTypes' + ''] = exactProp(VierComCssBaseline.propTypes);
}

export default VierComCssBaseline;
