import React from 'react';
import { Tab as MuiTab, Tabs as MuiTabs } from '@material-ui/core';
import { tabsOverflowButtonFactory } from './TabsOverflowButton';
import { makeStyles } from '@material-ui/core/styles';

const useStylesTabs = makeStyles(theme => ({
  root: {
    minHeight           : theme.dimensions.inputHeight,
    position            : 'relative',
    borderTopLeftRadius : theme.dimensions.borderRadiusPanel,
    borderTopRightRadius: theme.dimensions.borderRadiusPanel,
    boxShadow           : `inset 0px -1px ${theme.palette.divider}`,
    backgroundColor     : theme.palette.neutral[100],
  },
  indicator: {
    backgroundColor: 'transparent'
  }
}));

export const Tabs = props => {
  const classes = useStylesTabs();
  return (
    <MuiTabs
      {...props}
      classes={{ root: classes.root, indicator: classes.indicator }}
      scrollButtons="on"
      variant="scrollable"
      indicatorColor="primary"
      textColor="secondary"
      ScrollButtonComponent={tabsOverflowButtonFactory(props.children)}
    >
      {props.children}
    </MuiTabs>
  );
};

const useStylesTab = makeStyles(theme => ({
  root: {
    minHeight  : theme.dimensions.inputHeight,
    whiteSpace: 'nowrap',
    boxShadow : `inset -1px 0px ${theme.palette.divider}`,
    '& i'     : {
      fontSize     : '1.1rem !important',
      verticalAlign: 'middle',
      marginRight  : 5,
    },
    '& h3': {
      fontSize  : theme.typography.size.xs,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& > span > span': {
      display   : 'flex',
      alignItems: 'center'
    },
    textTransform: 'none'
  },
  textColorInherit: {
    color: theme.palette.neutral.contrastText
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color          : theme.palette.primary.contrastText
  },
}));

export const Tab = props => {
  const classes = useStylesTab();

  return (
    <MuiTab
      {...props}
      classes={{
        root            : classes.root,
        textColorInherit: classes.textColorInherit,
        selected        : classes.selected,
      }}
      textColor="inherit"
    >
      {props.children}
    </MuiTab>
  );
};
