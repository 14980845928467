import clsx from 'clsx';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Button as MuiButton } from '@material-ui/core';
import React, { useState, useRef } from 'react';
import { Popout } from './Popout';
import { Grow, Icon } from '..';
import { makeStyles, useTheme } from '../styles';

const useStyles = makeStyles(
  theme => ({
    root       : {},
    buttonStyle: {
      float           : 'left',
      cursor          : 'pointer',
      padding         : '5px',
      borderRadius    : theme.dimensions.borderRadiusPanel,
      minWidth        : 0,
      height          : 'auto',
      position        : 'relative',
      textTransform       : 'none',
      marginRight     : theme.dimensions.headerSpacing,
      transition      : `${theme.transitions.create('all')} !important`,
      '-ms-transition': `${theme.transitions.create('all')} !important`,
      boxShadow       : theme.dimensions.shadowButton,
      opacity         : props => (props.disabled === true ? '.6' : '1'),
      backgroundColor : props => {
        if (props.popoverOpened === true) return theme.palette.common.white;
        else if (props.bright === true && props.active === true)
          return theme.palette.common.white;
        else if (props.bright === true) return alpha(theme.palette.neutral.dark, 0.2);
        return alpha(theme.palette.neutral[100], 0.2);
      },
      color: props => {
        return props.popoverOpened === true || props.active === true || props.dark === true
          ? theme.palette.common.black
          : theme.palette.common.white;
      },
      '&:hover': {
        backgroundColor: props =>
          props.bright === true
            ? theme.palette.blue[900]
            : alpha(theme.palette.neutral[100], 0.5),
        color: props =>
          props.bright === true ? theme.palette.common.white : theme.palette.common.black,
      },
      [theme.breakpoints.up('lg')]: {
        '& + &': {
          marginRight: 0,
        },
      },
      '& > span > div': {
        padding                          : 5,
        textAlign                        : 'center',
        cursor                           : 'pointer',
        display                          : 'flex',
        alignItems                       : 'center',
        flexDirection                    : 'column',
        justifyContent                   : 'center',
        [theme.breakpoints.down('xs')]: {
          minWidth: 20,
          height  : 20,
        },
        [theme.breakpoints.up('sm')]: {
          minWidth: 25,
          height  : 25,
        },
        [theme.breakpoints.up('md')]: {
          minWidth: 25,
          height  : 25,
        },
        [theme.breakpoints.up('lg')]: {
          minWidth: 30,
          height  : 30,
        },
        [theme.breakpoints.up('xl')]: {
          minWidth: 40,
          height  : 40,
        },
        [theme.breakpoints.up('xxl')]: {
          minWidth: 50,
          height  : 50,
        },
      },
    },
    chevronStyle: {
      fontSize                         : '1.2em',
      position                         : 'absolute',
      right                            : 5,
      transition                       : theme.transitions.create('background-color'),
      textShadow                       : theme.dimensions.textShadow,
      [theme.breakpoints.down('xl')]: {
        right: 2,
      },
    },
    iconStyle: {
      textShadow                       : theme.dimensions.textShadow,
      [theme.breakpoints.down('md')]: {
        fontSize  : 16,
        lineHeight: '30px',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize  : 16,
        lineHeight: '24px',
      },
      [theme.breakpoints.up('xxl')]: {
        fontSize  : 18,
        lineHeight: '30px',
      },
    },
    labelStyle: {
      font                             : `${theme.typography.fontWeightMedium} ${theme.typography.size.xs} Din`,
      whiteSpace                       : 'nowrap',
      letterSpacing                    : '1px',
      userSelect                       : 'none',
      transition                       : theme.transitions.create('background-color'),
      textShadow                       : theme.dimensions.textShadow,
      [theme.breakpoints.down(1220)]: {
        display: 'none',
      },
      [theme.breakpoints.between('xl', 1220)]: {
        display: 'none',
      },
      [theme.breakpoints.up('xxl')]: {
        display: 'block',
      },
    },
  }),
  { name: 'HeaderButton' },
);

const Button = React.forwardRef((props, ref) => {
  const classes = useStyles(props);

  const passProps = { ...props };
  delete passProps.bright;
  delete passProps.dark;
  delete passProps.active;
  delete passProps.popoverOpened;
  delete passProps.chevron;
  delete passProps.drawerPaperProps;
  delete passProps.disableAnimation;

  return props.disableAnimation ? <MuiButton ref={ref}
    className={clsx(classes.root, classes.buttonStyle, 'header-button')}
    {...passProps}
  >
    {props.children}
  </MuiButton> : (
    <Grow in={true} mountOnEnter unmountOnExit>
      <MuiButton ref={ref}
        className={clsx(classes.root, classes.buttonStyle, 'header-button')}
        {...passProps}
      >
        {props.children}
      </MuiButton>
    </Grow>
  );
});

/**
 *
 * HeaderButton
 *
 * Callbacks
 * onClick()                    => triggered, if the button pushed / returns {event}
 * onChevronClick()             => triggered, if the chevron pushed / returns {event}
 * onStateChanged()             => returns the current open State {boolean}
 *
 * Key Events
 * onKeyEscape()                => triggered if Escape was pushed : default behaviour: close popover
 * onKeyEnter()                 => triggered if Enter was pushed
 * onKeyArrow()                 => returns {'up','down','left' or 'right'}
 * onKeyDown()                  => returns the event
 *
 * Properties
 * open {boolean}               => enable/disable the button from outside
 * chevronSize{float}           => the size of the chevron button ( default: 0.7 )
 * chevron {boolean}
 * noPadding {boolean}
 * bright {boolean}
 * disabled {boolean}
 * label {string}
 * icon {string}
 * anchorElement
 * anchorOrigin {object}        => default { vertical: 'bottom', horizontal: 'left' }
 * transformOrigin {object}     => default { vertical: 'top', horizontal: 'left' }
 * popoverClass {string}
 * data-element {string}
 */

const HeaderButton = props => {
  const [state, setState] = useState({ open: false });
  const _buttonRef = useRef(null);
  const classes = useStyles(props);
  const theme = useTheme();

  function _isOpen() {
    return props.open != 'undefined' ? props.open : state.open;
  }

  function _open() {
    if (props.onStateChanged) props.onStateChanged(true);
    setState({ ...state, open: true });
  }

  function _close() {
    if (props.onStateChanged) props.onStateChanged(false);
    setState({ ...state, open: false });
  }

  function _toggle() {
    props.children && _isOpen() === false ? _open() : _close();
  }

  function _onKeyDown(event) {
    switch (event.key) {
    case 'Escape': // 27
      props.onKeyEscape ? props.onKeyEscape() : _close();
      break;
    case 'Enter': // 13
      if (props.onKeyEnter) {
        props.onKeyEnter();
      }
      if (!_isOpen()) {
        _open();
        event.stopPropagation();
        event.preventDefault();
      }
      break;
    case 'ArrowUp':
      if (props.onKeyArrow) {
        _onKeyArrow('up');
      }
      break;
    case 'ArrowDown':
      if (props.onKeyArrow) {
        _onKeyArrow('down');
      }
      break;
    case 'ArrowLeft':
      if (props.onKeyArrow) {
        _onKeyArrow('left');
      }
      break;
    case 'ArrowRight':
      if (props.onKeyArrow) {
        _onKeyArrow('right');
      }
      break;
    case ' ': // Keycode 32
      _onKeySpace();
      break;
    }

    if (props.onKeyDown) {
      props.onKeyDown(event);
    }
  }

  function _onKeySpace() {
    if (props.onKeySpace) {
      props.onKeySpace();
    }

    if (!_isOpen()) {
      _open();
      // Prevent sending the Key to the popover, if it was closed
      event.stopPropagation();
      event.preventDefault();
    }
  }

  function _onKeyArrow(dir) {
    if (props.onKeyArrow) {
      props.onKeyArrow(dir);
    }
  }

  //onClick event is triggered on 'mouse click' and 'enter' or 'space' hits
  //e.detail returns '1' when is triggered precisely by 'mouse click'
  function _onClick(e) {
    if (!e.detail) return;
    if (
      props.chevron &&
      _isChevronClick(
        e.clientX,
        e.currentTarget.getBoundingClientRect(),
        e.currentTarget.offsetWidth,
        props.chevronSize ? props.chevronSize : 0.7,
      )
    ) {
      props.onChevronClick ? props.onChevronClick(e) : _toggle();
    } else {
      props.onClick ? props.onClick(e) : _toggle();
    }
  }

  function _isChevronClick(clientX, buttonRect, buttonOffsetWidth, chevronSize) {
    return clientX - buttonRect.left >= buttonOffsetWidth * chevronSize;
  }

  return (
    <div>
      <Button
        classes={{ ...props.classes }}
        data-element={props['data-element']}
        bright={props.bright}
        dark={props.dark}
        active={props.active}
        chevron={props.chevron}
        title={props.label}
        aria-label={props.label}
        onClick={_onClick}
        popoverOpened={_isOpen()}
        ref={_buttonRef}
        disabled={props.disabled}
        onKeyDown={_onKeyDown}
        disableAnimation={props.disableAnimation || false}
        aria-haspopup={props.ariaHaspopup}
        aria-expanded={props.ariaExpanded}
        aria-controls={props.ariaControls}
      >
        <div>
          <Icon className={clsx(classes.iconStyle, 'icon-style')}>
            {props.icon}
          </Icon>
          {props.label && (
            <span className={clsx(classes.labelStyle, 'label-style')}>{props.label}</span>
          )}
          {props.chevron && (
            <Icon
              className={classes.chevronStyle}
              data-element="header-button.expand-chevron"
            >
              {_isOpen() ? 'expand_less' : 'expand_more'}
            </Icon>
          )}
        </div>
      </Button>
      {props.children && _isOpen() && (
        <Popout
          classes={{ paper: props.popoverClass }}
          anchorEl={props.anchorElement ? props.anchorElement : _buttonRef.current}
          onClose={_close}
          onKeyDown={_onKeyDown}
          popoverPaperProps={{
            style: {
              minWidth    : '250px',
              marginTop   : '5px',
              padding     : props.noPadding ? 0 : '10px',
              borderRadius: '4px',
              overflowX   : 'hidden',
              boxShadow   : theme.dimensions.shadowDialog,
            },
          }}
          drawerPaperProps={{
            style: {
              padding: props.noPadding ? 0 : '1em',
              bottom : 0,
            },
          }}
          anchorOrigin={
            props.anchorOrigin ? props.anchorOrigin : { vertical: 'bottom', horizontal: 'left' }
          }
          transformOrigin={
            props.transformOrigin ? props.transformOrigin : { vertical: 'top', horizontal: 'left' }
          }
          open={_isOpen()}
        >
          {props.children}
        </Popout>
      )}
    </div>
  );
};

export default HeaderButton;
