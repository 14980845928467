import React from 'react';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Icon, Menu } from '..';
import { makeStyles, useTheme } from '../styles';
const useStyles = makeStyles(theme => ({
  containerStyle: {
    transition     : theme.transitions.create('background-color'),
    position       : 'absolute',
    right          : 0,
    alignSelf      : 'center',
    backgroundColor: theme.palette.primary.main,
    marginRight    : 5,
    borderRadius   : 20,
    zIndex         : 1,
    '&:hover'      : {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonStyle: {
    padding: '2px 1px 4px 1px',
    cursor : 'pointer',
    '& i'  : {
      fontSize : 12,
      marginTop: 3,
      color    : theme.palette.primary.contrastText,
    },
  },
  menuStyle: {
    button: {
      textAlign  : 'left',
      borderColor: 'transparent',
    },
    ul: {
      padding: 0,
    },
  },
}));

// Its not the best solution, but for this it works...
const TabRender = React.forwardRef((props, _ref) => <>{props.children}</>);

export const tabsOverflowButtonFactory = children => {
  return props => (
    <TabsOverflowButton {...props}>
      <TabRender>{children}</TabRender>
    </TabsOverflowButton>
  );
};

export const TabsOverflowButton = props => {
  const classes = useStyles(props);
  const theme = useTheme();
  if (props.disabled) {
    // We don't need the left one as we are not actually scrolling but overflowing.
    // [4kim, 22.06.20] The left overflow Button is now automatically hidden (since Version change 215ca4bad)
    return null;
  }

  return (
    <div className={classes.containerStyle}>
      <PopupState variant="popover" popupId="popup-popover">
        {popupState => (
          <React.Fragment>
            <div className={classes.buttonStyle} {...bindTrigger(popupState)} aria-label="Show more Tabs">
              <Icon>more_vert</Icon>
            </div>

            <Menu
              className={classes.menuStyle}
              MenuListProps={{ style: { display: 'flex', flexDirection: 'column' } }}
              {...bindMenu(popupState)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{ style: { boxShadow: theme.dimensions.shadowDialog } }}
            >
              {props.children}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </div>
  );
};
