(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@core/actions"), require("@core/events"), require("@pcweb/controls"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["@core/actions", "@core/events", "@pcweb/controls", "react"], factory);
	else if(typeof exports === 'object')
		exports["@core/hotkeys"] = factory(require("@core/actions"), require("@core/events"), require("@pcweb/controls"), require("react"));
	else
		root["@core/hotkeys"] = factory(root["@core/actions"], root["@core/events"], root["@pcweb/controls"], root["react"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__core_actions__, __WEBPACK_EXTERNAL_MODULE__core_events__, __WEBPACK_EXTERNAL_MODULE__pcweb_controls__, __WEBPACK_EXTERNAL_MODULE_react__) {
return 