import { Destroyable } from '@multichannel/sdk/src/Destroyable';
import Compareable from '@multichannel/sdk/src/Comparable';
import moment from 'moment';
import {
  observable,
  action,
  computed,
  runInAction,
  ObservableMap
} from 'mobx';
import {
  MessengerJob,
  MessengerJobTemplate,
  MessengerJobConfig
} from './MessengerJob';
import { MessageHandler } from '@core/messages';
import Actions from '@core/actions';

export type DataFieldValue = any;
export type SyntheticDataSet = any;
/**
 *  Mainly Messenger
 */
export class Messenger extends Compareable implements Destroyable {

  /**
   * NOTE
   * observable(Map) was replaced with ObservableMap due to ts warning of types inconsistency
   * this should be OK, but might be checked in case some wild misbehaviour appears, ENG-7948
  */
  @observable public jobList: ObservableMap<number, MessengerJobTemplate>;
  public jobSelectionAllowed: boolean;

  private _messageHandler: MessageHandler;
  private _subscriptions;
  private _sdkAuth;
  private _customerId: string;
  private _logger: any;

  @observable public dataset: SyntheticDataSet;
  @observable public dialogOpen: boolean;
  @observable private defaultSyntheticDataSet: SyntheticDataSet = {
    activeMessengerJob: null
  };

  constructor(
    serviceName: string,
    messageHandler: MessageHandler,
    sdkAuth: any,
    customerId: string,
    loggerFactory: any,
    jobSelectionAllowed: boolean = false
  ) {
    super();
    const nameSpace = `core.messenger.${serviceName}`;

    this._messageHandler = messageHandler;
    this._sdkAuth = sdkAuth;
    this._customerId = customerId;
    this._logger = loggerFactory(nameSpace);

    this.jobSelectionAllowed = jobSelectionAllowed;
    const getMessage = this._messageHandler.messagesByType;

    this._subscriptions = [
      getMessage(10027).subscribe(res => this._handleMessengerJobTemplates(res?.obj)),
      getMessage(10034).subscribe(res => this._handleMessengerJobs(res?.obj))
    ];

    runInAction(() => {
      this.jobList = new ObservableMap<number, MessengerJobTemplate>();
      this.dialogOpen = false;
    });

    Actions.register(`${nameSpace}.open`, (dataset?: SyntheticDataSet) => {
      this.openJob(dataset);
    });
  }

  @action public selectJob(jobId: number) {
    if(this.jobList.has(jobId)) {
      const job = this.createJob({
        pos             : 1,
        jobId           : jobId,
        mode            : 1,
        channel         : 4,
        multipleChannels: true
      });

      job.reloadTemplate();

      this.dataset  = {
        activeMessengerJob: job
      };
    }
  }

  @action public openJob(dataset: SyntheticDataSet) {
    this.dataset = dataset || this.defaultSyntheticDataSet;
    this.dialogOpen = true;
  }

  @action public closeDialog(): void {
    this.dialogOpen = false;
  }

  public requestMessengerJobs(): void {
    this._logger.traceCall('requestMessengerJobs');
    this._messageHandler.send(10033, this._sdkAuth.me?.sessionKey, this._sdkAuth.me?.id, this._customerId);
  }

  public requestMessengerJobTemplate(jobId: number, force: boolean = false): void {
    this._logger.traceCall('requestMessengerJobTemplate');
    if(this.jobList.has(jobId)) {
      if(!this.jobList.get(jobId).loaded || force) {
        this._messageHandler.send(10026, this._sdkAuth.me?.sessionKey, this._customerId, 0, jobId);
      }
    }
  }

  public finishJob(jobId: number, channel: number, receiver: string, message: any, letterBox): void {

    this._logger.traceCall('finishJob', {
      channel,
      jobId
    });

    this._messageHandler.send(
      10028,
      this._sdkAuth.me?.sessionKey,
      this._customerId,
      0,
      jobId,
      receiver,
      channel,
      moment().format('YYYY-MM-DD hh:mm:ss'),
      message,
      letterBox
    );
  }

  public createJob(config: MessengerJobConfig): MessengerJob {
    this._logger.traceCall('createJob', config);
    if (!this.jobList.has(config.jobId)) return undefined;

    const newJob: MessengerJob = new MessengerJob(config, this.jobList.get(config.jobId), this);

    return newJob;
  }

  @action private _handleMessengerJobs(resp: any): void {

    this._logger.traceCall('handleMessengerJobs', resp);

    if(resp) {

      // Check if the response has an error
      if (resp.responsecode === '0') {

        const jobOverviewList = resp.ordersList || [];

        jobOverviewList.forEach(job => {

          const jobId = parseInt(job.id, 10);

          if (!this.jobList.has(jobId)) {
            this.jobList.set(jobId, new MessengerJobTemplate(jobId, job));
          }

        });
      }
    }
  }

  @action private _handleMessengerJobTemplates(resp: any): void {

    this._logger.traceCall('handleMessengerJobTemplates', resp);

    if(resp) {
      const jobId:number = parseInt(resp.jobid, 10);

      if(this.jobList.has(jobId)) {
        this.jobList.get(jobId).handleChannelTemplate(resp);
      }
    }
  }

  @computed get allJobs(): MessengerJobTemplate[] {
    return Array.from(this.jobList.values());
  }

  public destroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

