import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@pcweb/controls';


const HotkeyList = ({ hotkeys , translate}) => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {translate('Tastenkombination')}
            </TableCell>
            <TableCell>
              {translate('Verknüpfte_Funktion')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hotkeys.map((hotkey, index) => (
            <TableRow key={index}>
              <TableCell>{hotkey.translatedKey}</TableCell>
              <TableCell>{hotkey.translatedName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default HotkeyList;