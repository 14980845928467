import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {},
});

const Icon = props => {
  const classes = useStyles();
  return (
    <i {...props} className={clsx(classes.root, 'material-icons-4com', props.className)} role="img" aria-hidden="true">
      {props.children}
    </i>
  );
};

export default Icon;
