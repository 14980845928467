import { makeStyles } from '@pcweb/controls';
import clsx from 'clsx';
import React from 'react';
import { BadgeComp, Icon } from '../Package';

const useStyles = makeStyles(muiTheme => ({
  iconStyle: {
    userSelect  : 'none',
    borderRadius: '50%',
    padding     : '2px',
    border      : `2px solid ${muiTheme.palette.common.white}`,
    '&.grey'    : {
      backgroundColor: muiTheme.palette.text.disabled,
    },
    '&.green': {
      backgroundColor: muiTheme.palette.status.free.main,
    },
    '&.red': {
      backgroundColor: muiTheme.palette.status.busy.main,
    },
    '&.blue': {
      backgroundColor: muiTheme.palette.status.break.main,
    },
    '&.orange': {
      backgroundColor: muiTheme.palette.status.reserved.main,
    },
    '&.yellow': {
      backgroundColor: muiTheme.palette.status.refinish.main,
    },
    '& > i': {
      color   : muiTheme.palette.common.white,
      fontSize: '12px',
    },
  },
}));

const statusIcon = (statusName, classes) => {
  const statusMap = {
    ['AutoLoggedOff']: (
      <div className={clsx(classes.iconStyle, 'grey')} aria-label="Auto Logged off">
        <Icon>clear</Icon>
      </div>
    ),
    ['Abgemeldet']: (
      <div className={clsx(classes.iconStyle, 'grey')} aria-label="Logged off">
        <Icon>clear</Icon>
      </div>
    ),
    ['Pause']: (
      <div className={clsx(classes.iconStyle, 'blue')} aria-label="Pause">
        <Icon>status_pause</Icon>
      </div>
    ),
    ['Bereit']: (
      <div className={clsx(classes.iconStyle, 'green')} aria-label="Ready">
        <Icon>check</Icon>
      </div>
    ),
    ['Reserviert']: (
      <div className={clsx(classes.iconStyle, 'orange')} aria-label="Reserved">
        <Icon>flag</Icon>
      </div>
    ),
    ['MessageSent']: (
      <div className={clsx(classes.iconStyle, 'orange')} aria-label="Message sent">
        <Icon>flag</Icon>
      </div>
    ),
    ['In Anwahl']: (
      <div className={clsx(classes.iconStyle, 'red')} aria-label="Dialing">
        <Icon>call_received</Icon>
      </div>
    ),
    ['Im Gespräch']: (
      <div className={clsx(classes.iconStyle, 'red')} aria-label="Talking">
        <Icon>call_received</Icon>
      </div>
    ),
    ['Konferenz']: (
      <div className={clsx(classes.iconStyle, 'red')} aria-label="Conference">
        <Icon>call_received</Icon>
      </div>
    ),
    ['BusyUms']: (
      <div className={clsx(classes.iconStyle, 'red')} aria-label="Busy UMS">
        <Icon>call_received</Icon>
      </div>
    ),
    ['Nachbearbeitung']: (
      <div className={clsx(classes.iconStyle, 'yellow')} aria-label="Wrap-up">
        <Icon>status_edit</Icon>
      </div>
    ),
    ['Codierung / Nachbearbeitung']: (
      <div className={clsx(classes.iconStyle, 'yellow')} aria-label="Classification Wrap-up">
        <Icon>status_edit</Icon>
      </div>
    ),
    ['OutboundCall']: (
      <div className={clsx(classes.iconStyle, 'red')} aria-label="Outbound Call">
        <Icon>call_made</Icon>
      </div>
    ),
    ['Ausgehender Anruf']: (
      <div className={clsx(classes.iconStyle, 'red')} aria-label="Outgoing Call">
        <Icon>call_made</Icon>
      </div>
    ),
    ['BusyLocalTapi']: (
      <div className={clsx(classes.iconStyle, 'red')}>
        <Icon>call_received</Icon>
      </div>
    ),
  };

  if (!statusMap[statusName]) {
    return (
      <div className={clsx(classes.iconStyle, 'red')} aria-label="Local busy TAPI">
        <Icon>call_received</Icon>
      </div>
    );
  }
  return statusMap[statusName];
};

const AvatarBadgeComp = props => {
  const classes = useStyles();

  return (
    <BadgeComp badgeContent={statusIcon(props.statusName, props.classes ? props.classes : classes)}>
      {props.children}
    </BadgeComp>
  );
};
export default AvatarBadgeComp;
