import {
  observable,
  action,
  runInAction,
  reaction,
  computed
} from 'mobx';
import { Destroyable } from '@multichannel/sdk/src/Destroyable';
import Compareable from '@multichannel/sdk/src/Comparable';
import { Messenger } from './Messenger';
import * as Constants from './MessengerConstants';

interface MessengerDefaultReceiver {
  mail?: string;
  sms?: string;
  letter?: string;
  fax?: string;
}

export interface MessengerJobConfig {
    pos: number;
    jobId: number;
    mode: number; // MessengerMode
    defaultReceiver?: MessengerDefaultReceiver | null;
    channel: number; // MessengerChannelTemplateState
    multipleChannels?: boolean; // Is it possible to switch the channels?
}

export interface MessengerShortcode {
    addremail: string;
    addrfax: string;
    addrsms: string;
    addrvoice: string;
    description: string;
    name: string;
}

const {
  MessengerMode,
  MessengerChannelKind,
} = Constants;

export const createMessengerJobConfig = (position: number, job: MessengerJob | any): MessengerJobConfig | undefined => {
  const jobId = parseInt(job.jobId, 10);

  const modeEmail: number = parseInt(job.modeEmail, 10);
  const modeFax: number = parseInt(job.modeFax ,10);
  const modeSms: number = parseInt(job.modeSms ,10);
  const modeLetter: number = parseInt(job.modeLetter ,10);

  let mode = MessengerMode.Inactive;
  let channel = MessengerChannelKind.Nothing;

  if(modeEmail !== MessengerMode.Inactive) {
    mode = modeEmail;
    channel = MessengerChannelKind.Email;
  } else if(modeFax !== MessengerMode.Inactive) {
    mode = modeFax;
    channel = MessengerChannelKind.Fax;
  } else if(modeSms !== MessengerMode.Inactive) {
    mode = modeSms;
    channel = MessengerChannelKind.Sms;
  }  else if(modeLetter !== MessengerMode.Inactive) {
    mode = modeLetter;
    channel = MessengerChannelKind.Letter;
  }

  return (
    mode === MessengerMode.Inactive ||
        channel ===  MessengerChannelKind.Nothing
  ) ?
    undefined
    : {
      pos: position,
      jobId,
      mode,
      channel
    };
};

export class MessengerJobTemplate {

    public id;
    public name;
    public description;
    public editable; // Is the template editable
    public channelList: Array<number>;

    @observable public emailTemplate: string;
    @observable public emailAttachmentTemplate: string;
    @observable public smsTemplate: string;
    @observable public faxTemplate: string;
    @observable public letterTemplate: string;
    @observable public letterboxConfig: any;
    @observable public shortCodes: Array<MessengerShortcode>;
    @observable public shortCodeDefault: string;
    @observable public shortCodeDefaultEditable: boolean;

    @observable public permLongSms:boolean;

    constructor( id:number, job:any ) {

      this.id = id;

      this.name = job?.name || '';
      this.description = job?.description || '';
      this.editable = !!parseInt(job?.changeperm || '0', 10);

      runInAction(() => {
        this.emailTemplate = undefined;
        this.emailAttachmentTemplate = undefined;
        this.smsTemplate = undefined;
        this.faxTemplate = undefined;
        this.letterTemplate = undefined;
        this.letterboxConfig = undefined;
        this.permLongSms = false;
        this.shortCodeDefaultEditable = true;
        this.shortCodes = [];
        this.shortCodeDefault = '';
      });

      const channels = job?.channelsList || [];

      if(typeof channels === 'object') {
        this.channelList = channels.map(value => parseInt(value, 10));
      } else {
        this.channelList = [parseInt(channels,10)];
      }
    }

    @action public handleChannelTemplate(resp:any):void {

      if(resp?.templatemail?.umsdataservicecommentemail === '0') {
        this.emailTemplate = resp.templatemail.templateemail;
      }

      if(resp?.templatefax?.umsdataservicecommentfax === '0') {
        this.faxTemplate = resp.templatefax.templatefax;
      }

      if(resp?.templatesms?.umsdataservicecommentsms === '0') {
        this.smsTemplate = resp.templatesms.templatesms;
        this.permLongSms = !!parseInt(resp.templatesms.permlongsms, 10);
      }

      if(resp.templateletterbox.umsdataservicecommentletterbox === '0') {
        this.letterTemplate = resp.templateletterbox.templateletterbox;
      }

      if(resp.templatemailattachmtext.umsdataservicecommentemailattachmtext === '0') {
        this.emailAttachmentTemplate = resp.templatemailattachmtext.templateemailattachmtext;
      }

      this.letterboxConfig = resp.letterboxconfig || [];
      this.shortCodes = resp.shortcodesconfig?.shortcodesList || [];
      this.shortCodeDefault = resp.shortcodesconfig?.defaultshortdialid;
      this.shortCodeDefaultEditable = !!parseInt(resp.shortcodesconfig?.changingdefaultidallowed, 10);
    }

    @computed get loaded() {
      return !!this.emailTemplate || !!this.smsTemplate || !!this.faxTemplate || !!this.letterTemplate;
    }

    public getTemplate(mode:any):string {
      switch(mode) {
      case MessengerChannelKind.Email:
        return this.emailTemplate;
      case MessengerChannelKind.Sms:
        return this.smsTemplate;
      case MessengerChannelKind.Fax:
        return this.faxTemplate;
      case MessengerChannelKind.Letter:
        return this.letterTemplate;
      default:
        break;
      }

      return '';
    }
}

export class MessengerJob extends Compareable implements Destroyable {
      @observable private _config: MessengerJobConfig = undefined;
      private _disposer: any;
      private _replaceCallback: Function | null;
      private _finishCallback: Function | null;
      private _sendAttempt: number = 0;

      @observable public template: MessengerJobTemplate;
      @observable public subject: string;
      @observable public message: string;
      @observable public receiverAddress: string;
      @observable public pdfChecked: boolean;
      @observable public pdfMessage: string;

      @observable public letterBox = {
        config      : null,
        values      : new Array(11).fill(''),
        keys        : ['identifier', 'salutation', 'name1', 'name2', 'name3',  'street', 'houseNo', 'postCode', 'city', 'country',  'countryIsoCode'],
        getObject   : function() { let obj = {}; this.keys.forEach((key: string, index: number) => obj[key] = this.values[index]); return obj; },
        setValue    : function(key: number, value) { this.values[key] = value; },
        getValue    : function(key: number) { return this.values[key]; },
        isFieldValid: function(key: number) { return this.values[key].length > 0; },
        /*{ return Number(this.config?.[this.keys[key]]?.lengthmax) <= this.values[key]?.length || false; },*/
        isValid     : function() { return !this.values.some(v => v === ''); }
      };

      constructor(
        config: MessengerJobConfig,
        template: MessengerJobTemplate,
        private _messenger: Messenger
      ) {

        super();

        this._config = config;
        this._replaceCallback = null;
        this._finishCallback = null;

        runInAction(() => {
          this.subject = '';
          this.message = '';
          this.receiverAddress = this.defaultReceiver;
          this.pdfMessage = '';
          this.pdfChecked = false;
          this.template = template;
        });

        this._disposer = reaction(
          () => [
            this.template.emailTemplate,
            this.template.smsTemplate,
            this.template.faxTemplate,
            this.template.letterTemplate,
            this.template.emailAttachmentTemplate,
            this.template.shortCodeDefault
          ],
          _ => {
            const templateMessage = this.template.getTemplate(this.channel);

            if(templateMessage) {
              if(this.isEmail) {
                // We need to split the first line, because the subject is also included in this string
                const firstNewLine = templateMessage.indexOf('\r\n');

                this.setSubject(templateMessage.substr(0,firstNewLine).trim());
                this.setMessage(templateMessage.substr(firstNewLine+2).trim());

                this.setPdfMessage(this.template.emailAttachmentTemplate);

              } else if (this.isLetter){
                this.setMessage(this.template.letterboxConfig.msg || '');
              } else {
                this.setMessage(templateMessage);
              }
            }

            if (this.template.letterboxConfig) {
              this.letterBox.config = this.template.letterboxConfig;
            }

            if(this.template.shortCodeDefault) {
              const foundDefault = this.template.shortCodes.find(shortDial => shortDial.name === this.template.shortCodeDefault);
              if(foundDefault && foundDefault[this.channelId]) {
                this.receiverAddress = foundDefault[this.channelId];
              }
            }

          },
          { fireImmediately: true },
        );

      }

      @action public setChannelEmail():void {
        if(this.isChannelSwitchable) {
          this._config.channel = MessengerChannelKind.Email;
          this.receiverAddress = this.defaultReceiver;
        }
      }

      @action public setChannelSms():void {
        if(this.isChannelSwitchable) {
          this._config.channel = MessengerChannelKind.Sms;
          this.receiverAddress = this.defaultReceiver;
        }
      }

      @action public setChannelFax():void {
        if(this.isChannelSwitchable) {
          this._config.channel = MessengerChannelKind.Fax;
          this.receiverAddress = this.defaultReceiver;
        }
      }

      @action public setChannelLetter():void {
        if(this.isChannelSwitchable) {
          this._config.channel = MessengerChannelKind.Letter;
          this.receiverAddress = this.defaultReceiver;
        }
      }

      @action public setSubject(text:string):void {
        this.subject = text;
      }

      @computed get letterReceiverValid(){
        return this.letterBox.isValid();
      }

      public replaceMarkers(message:string) : string {
        if(this._replaceCallback) {
          return this._replaceCallback(message);
        }

        return message;
      }

      public addReplacementCallback(callback:Function):void {
        this._replaceCallback = callback;
      }

      public addFinishCallback(callback:Function):void {
        this._finishCallback = callback;
      }

      @action public setMessage(message:string):void{
        runInAction(() => this.message = this.replaceMarkers(message));
      }

      @action public setReceiverAddress(address:string):void {
        this.receiverAddress = address;
      }

      @action public setPdfMessage(message:string):void {
        runInAction(() => this.pdfMessage = message ? this.replaceMarkers(message) : '');
      }

      @action public togglePdfChecked():void {
        this.pdfChecked = !this.pdfChecked;
      }

      @computed get jobValid() {
        if(!this.receiverValid) return false;

        if(this.isEmail) {
          if(this.message && this.subject) {
            if(this.pdfChecked && !this.pdfMessage) return false;
            return true;
          }
        } else if (this.isLetter) {
          if (this.letterBox.isValid() && this.messageValid) return true;
        }
        else {
          if (this.message) return true;
        }

        return false;
      }

      @computed get receiverValid() {
        if (this.isLetter) return true;
        if(!this.receiverAddress) return false;
        if(this.isEmail && this.receiverAddress.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/gi)) return true; // eslint-disable-line
        if((this.isSms || this.isFax) && this.receiverAddress.match(/^(\+\d{2})?\d{6}\d*$/gm)) return true;


        return false;
      }

      @computed get messageValid() {
        if(this.message.length <= this.messageMaxLength) return true;
        return false;
      }

      @computed get messageMaxLength() {
        if(this.isSms) {
          if(this.template.permLongSms) return 1200;
          return 160;
        }
        return 65535;
      }

      @computed get messageShortDials() {
        const availableShortDials = this.template?.shortCodes || [];
        return availableShortDials.filter(shortDial => shortDial[this.channelId]);
      }

      @computed get messageReceiverEditable() {
        return !this.template?.shortCodeDefault || this.template?.shortCodeDefaultEditable === true;
      }

      @computed get messageEditable() {
        return !!this.template?.editable;
      }

      @computed get defaultReceiver(): string {
        if(this._config.defaultReceiver) {
          if(this.isEmail) {
            return this._config.defaultReceiver.mail;
          }
          if(this.isSms) {
            return this._config.defaultReceiver.sms;
          }
          if(this.isFax) {
            return this._config.defaultReceiver.fax;
          }
          if(this.isLetter) {
            return this._config.defaultReceiver.letter;
          }
        }

        if(this.template?.shortCodeDefault) {
          const foundDefault = this.template.shortCodes.find(shortDial => shortDial.name === this.template.shortCodeDefault);
          if(foundDefault && foundDefault[this.channelId]) {
            return foundDefault[this.channelId];
          }
        }

        return '';
      }

      get fullMessage():string {
        return this.isEmail ? `${this.subject.trim()}\r\n${this.message}` : this.message;
      }

      get jobName() {
        return this.template.name;
      }

      get mode() {
        return this._config.mode;
      }

      get isAutomatic() {
        return this._config.mode === MessengerMode.Automatic;
      }

      get channel() {
        return this._config.channel;
      }

      get isChannelSwitchable() {
        return !!this._config.multipleChannels;
      }

      get isFax() {
        return this._config.channel === MessengerChannelKind.Fax;
      }

      get isEmail() {
        return this._config.channel === MessengerChannelKind.Email;
      }

      get isLetter() {
        return this._config.channel === MessengerChannelKind.Letter;
      }

      get isSms() {
        return this._config.channel === MessengerChannelKind.Sms;
      }

      get channelId() {
        if (this.isEmail) {
          return 'addremail';
        }
        return this.isFax ? 'addrfax' : 'addrsms';
      }

      get pos() {
        return this._config.pos;
      }

      public reloadTemplate(force: boolean = false): void {
        this._messenger.requestMessengerJobTemplate(this._config.jobId, force);
      }

      public async finishAutomatically(): Promise<void> {
        this._sendAttempt += 1;

        if(this.jobValid) {
          this.finish(true);
          return;
        }

        if(this._sendAttempt > 9) {
          this.finish(false);
          return;
        }

        await new Promise(resolve => {
          window.setTimeout(() => resolve(true), 250);
        });

        this.finishAutomatically();
      }

      public finish(shouldSend:boolean = false):void {
        if(shouldSend) {
          this._messenger.finishJob(
            this._config.jobId,
            this._config.channel,
            this.receiverAddress,
            {
              message    : this.fullMessage,
              message2set: this.pdfChecked ? '1' : '',
              message2   : this.pdfChecked ? this.pdfMessage : ''
            },
            this.isLetter ? this.letterBox.getObject() : null,
          );
        }
        if(this._finishCallback) {
          this._finishCallback();
        }
      }


      public destroy():void {
        if(this._disposer) this._disposer();
      }
}