import { createTheme } from '@material-ui/core/styles';

const muiTheme = createTheme({
  breakpoints: {
    keys: {
      0: 'xs',
      1: 'sm',
      2: 'md',
      3: 'lg',
      4: 'xl',
      5: 'xxl',
    },
    values: {
      xs : 0,
      sm : 320,
      md : 640,
      lg : 960,
      xl : 1080,
      xxl: 1920,
    },
  },
  typography: {
    fontFamily       : 'DIN',
    fontWeightRegular: 300,
    fontWeightBold   : 500,
    size             : {
      xs: '0.875rem',
      s : '0.95rem',
      m : '1rem',
      l : '1.3rem',
      xl: '1.5rem',
    },
    h1: {
      fontFamily: 'DIN',
      fontWeight: 500,
      fontSize  : '1.3rem',
    },
    h2: {
      fontFamily: 'DIN',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    h3: {
      fontFamily: 'DIN',
      fontWeight: 500,
      fontSize  : '1.1rem',
    },
    h4: {
      fontFamily: 'DIN',
      fontWeight: 500,
      fontSize  : '1rem',
    },
    h5: {
      fontFamily: 'DIN',
      fontWeight: 500,
      fontSize  : '0.875rem',
    },
  },
  dimensions: {
    tableRowHeight      : '29.25px',
    inputHeightSmall    : '25px',
    inputHeight         : '31.25px',
    letterSpacing       : '0.04em',
    borderRadiusInput   : '4px',
    borderRadiusPanel   : '4px',
    borderRadiusListItem: '8px',
    moduleSpacing       : '7px',
    headerSpacing       : '5px',
    headerSpacingSmall  : '3px',
    shadowPanel         :
      '0px 2px 1px -1px rgba(28, 50, 87, 0.3), 0px 1px 1px 0px rgba(28, 50, 87, 0.24), 0px 1px 3px 0px rgba(28, 50, 87, 0.22)',
    shadowPanelHover:
      '0px 3px 1px -2px rgba(28, 50, 87, 0.3), 0px 2px 2px 0px rgba(28, 50, 87, 0.24), 0px 1px 5px 0px rgba(28, 50, 87, 0.22)',
    shadowDialog:
      '20px 20px 60px rgba(28, 50, 87,0.1), -20px -20px 60px rgba(28, 50, 87,0.1)',
    shadowButton:
      '0px 2px 1px -1px rgba(0,0,0, 0.2), 0px 1px 1px 0px rgba(0,0,0, 0.14), 0px 1px 3px 0px rgba(0,0,0, 0.12)',
    textShadow    : '1px 1px 1px rgba(0,0,0, 0.3)',
    neomorphShadow:
      '20px 20px 60px rgba(0,0,0,0.2), -20px -20px 60px rgba(0,0,0,0.0)',
    shadowGlobalNavigation:
      '0px 2px 1px 1px rgba(24, 39, 68, 0.3), 0px 1px 1px 0px rgba(24, 39, 68, 0.24), 0px 1px 3px 0px rgba(24, 39, 68, 0.22)',
    globalNavigationWidth: '64px',
  },
  images: {
    logo: {
      plugin: 'pcweb/gui',
      path  : 'images/VIER_Logo.svg',
    },
  },
  buttons: {
    layout: 'theme-button-layout--responsive',
  },
  palette: {
    primary: {
      50                  : '#ffe8e0',
      100                 : '#ffc5b3',
      200                 : '#ff9e80',
      300                 : '#ff774d',
      400                 : '#ff5926',
      500                 : '#ff3c00',
      600                 : '#ff3600',
      700                 : '#ff2e00',
      800                 : '#ff2700',
      900                 : '#ff1a00',
      A100                : '#ffffff',
      A200                : '#fff3f2',
      A400                : '#ffc3bf',
      A700                : '#ffaba6',
      contrastDefaultColor: 'light',
      light               : '#ff774d',
      main                : '#ff3c00',
      dark                : '#ff2e00',
      contrastText        : '#FFF',
    },
    secondary: {
      50                  : '#e2e0e2',
      100                 : '#b6b3b6',
      200                 : '#858085',
      300                 : '#544d54',
      400                 : '#2f262f',
      500                 : '#0a000a',
      600                 : '#090009',
      700                 : '#070007',
      800                 : '#050005',
      900                 : '#030003',
      A100                : '#a6a6a6',
      A200                : '#8c8c8c',
      A400                : '#737373',
      A700                : '#666666',
      contrastDefaultColor: 'light',
      light               : '#544d54',
      main                : '#0a000a',
      dark                : '#070007',
      contrastText        : '#FFF',
    },
    neutral: {
      50                  : '#fbfbfc',
      100                 : '#f5f6f7',
      200                 : '#eef0f2',
      300                 : '#e7e9ec',
      400                 : '#e2e5e8',
      500                 : '#dde0e4',
      600                 : '#d9dce1',
      700                 : '#d4d8dd',
      800                 : '#cfd3d9',
      900                 : '#c7cbd1',
      A100                : '#ffffff',
      A200                : '#ffffff',
      A400                : '#ffffff',
      A700                : '#ffffff',
      contrastDefaultColor: 'dark',
      light               : '#e7e9ec',
      main                : '#dde0e4',
      dark                : '#d4d8dd',
      contrastText        : '#0a000a',
    },
    blue: {
      50   : '#ECEEF1',
      100  : '#CFD4DC',
      200  : '#B0B8C4',
      300  : '#909BAC',
      400  : '#78859B',
      500  : '#607089',
      600  : '#3E5170',
      700  : '#1C3257',
      800  : '#192D4F',
      900  : '#111F3C',
      light: '#909BAC',
      main : '#607089',
      dark : '#1C3257',
    },
    status: {
      free: {
        light       : '#7fed7d',
        main        : '#67CC66',
        dark        : '#00891f',
        contrastText: {
          light: '#000000',
          main : '#000000',
          dark : '#000000',
        },
      },
      refinish: {
        light       : '#ffee4d',
        main        : '#F5BC00',
        dark        : '#bd8c00',
        contrastText: {
          light: '#000000',
          main : '#000000',
          dark : '#000000',
        },
      },
      reserved: {
        light       : '#ffa44a',
        main        : '#FF6333',
        dark        : '#bb4400',
        contrastText: {
          light: '#000000',
          main : '#000000',
          dark : '#fafafa',
        },
      },
      busy: {
        light       : '#ff563d',
        main        : '#D53b3b',
        dark        : '#9f0000',
        contrastText: {
          light: '#000000',
          main : '#000000',
          dark : '#fafafa',
        },
      },
      busyTapi: {
        light       : '#ff563d',
        main        : '#D53b3b',
        dark        : '#9f0000',
        contrastText: {
          light: '#000000',
          main : '#000000',
          dark : '#fafafa',
        },
      },
      break: {
        light       : '#80d5ff',
        main        : '#53A4E7',
        dark        : '#0076c2',
        contrastText: {
          light: '#000000',
          main : '#000000',
          dark : '#fafafa',
        },
      },
      logout: {
        light       : '#C0CACF',
        main        : '#4f4a4a',
        dark        : '#2D3133',
        contrastText: {
          light: '#000000',
          main : '#fafafa',
          dark : '#fafafa',
        },
      },
    },
    text: {
      primary  : '#0a000a',
      secondary: '#544d54',
      tertiary : '#858085',
      disabled : '#B6B3B6',
    },
    error: {
      light: '#ff563d',
      main : '#D53b3b',
      dark : '#9f0000',
    },
    success: {
      light       : '#7fed7d',
      main        : '#49BA4E',
      dark        : '#00891f',
      contrastText: '#0a000a',
    },
    info: {
      light: '#80d5ff',
      main : '#53A4E7',
      dark : '#0076c2',
    },
    action: {
      hover   : '#eef0f2',
      selected: '#e2e5e8',
    },
    divider: '#d4d8dd',
    utility: {
      green: {
        light: '#7fed7d',
        main : '#49BA4E',
        dark : '#00891f',
      },
      yellow: {
        light: '#ffee4d',
        main : '#F5BC00',
        dark : '#bd8c00',
      },
      orange: {
        light: '#ffa44a',
        main : '#F57316',
        dark : '#bb4400',
      },
      red: {
        light: '#ff563d',
        main : '#D91111',
        dark : '#9f0000',
      },
      pink: {
        light: '#ff7beb',
        main : '#F542B9',
        dark : '#BE0089',
      },
      violet: {
        light: '#D66EFF',
        main : '#A13BD9',
        dark : '#6D00A6',
      },
      darkBlue: {
        light: '#7373F5',
        main : '#4242F5',
        dark : '#0017C1',
      },
      blue: {
        light: '#80d5ff',
        main : '#42A4F5',
        dark : '#0076c2',
      },
      teal: {
        light: '#84ffff',
        main : '#42E9F5',
        dark : '#00b6c2',
      },
    },
  },
});

export default muiTheme;
